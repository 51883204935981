/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form } from '@unform/web';
import FileSaver from 'file-saver';
import { utils, write } from 'xlsx';
import { CgSoftwareDownload } from 'react-icons/cg';
import { RiCloseFill } from 'react-icons/ri';
import { isToday, isYesterday, subDays, getUnixTime } from 'date-fns';

import api from '../../services/api';
import formatDate from '../../utils/formatDate';

import Select from '../Select';
import Button from '../Button';
import {
  Container,
  Content,
  Disclaimer,
  Filter,
  LogList,
  LogRegister,
  UserAvatar,
  LogDescription,
  NoLogMessage,
} from './styles';

import { ReactComponent as HelpCircleIcon } from '../../assets/help-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle.svg';
import { ReactComponent as XCircleIcon } from '../../assets/x-circle.svg';
import getInitialNameLetters from '../../utils/getInitialNameLetters';
import ICableDataDTO2 from '../../dtos/ICableDataDTO2';
import ILogStatusDataDTO from '../../dtos/ILogStatusDataDTO';
import ILogUserActionDataDTO from '../../dtos/ILogUserActionDataDTO';

interface IOption {
  value: string;
  label: string;
}

interface ILogData {
  center?: string;
  date_time: Date;
  message: string;
  user_name?: string;
  type: string;
}

interface ILogType {
  type: string; // 'center' | 'user' | 'ok';
  value: string;
}

interface ILogDataFormatted {
  title: string[];
  message: string;
  time: string;
  date: string;
  logType: ILogType;
}

interface ICableMainData {
  id: string;
  center: string;
}

interface LogQueryFormData {
  cableId: string;
  period: string;
  type: string;
}

interface LogProps {
  cablesList: ICableMainData[];
  currentCable?: ICableDataDTO2;
  close(): void;
}

const Log: React.FC<LogProps> = ({ cablesList, currentCable, close }) => {
  const [logData, setLogData] = useState<ILogData[]>([]);
  const [logFileData, setLogFileData] = useState<
    [ILogStatusDataDTO[], ILogUserActionDataDTO[]]
  >();

  const handleLogDataFormatted = useCallback((data: any, type: string) => {
    const logCablesFormatted: ILogData[] = data[0].map((logCable: any) => {
      const log: ILogData = {
        center: logCable.center,
        date_time: logCable.date_time,
        message: logCable.message,
        type,
      };

      return log;
    });

    const logUsersFormatted: ILogData[] = data[1].map((logUser: any) => {
      const log: ILogData = {
        center: logUser.cable.center,
        date_time: logUser.date_time,
        message: logUser.message,
        user_name: logUser.author.name,
        type,
      };

      return log;
    });

    let logCablesSorted: ILogData[];
    if (type === 'all') {
      logCablesSorted = logCablesFormatted.concat(logUsersFormatted);
    } else if (type === 'user') {
      logCablesSorted = logUsersFormatted;
    } else {
      logCablesSorted = logCablesFormatted;
    }

    logCablesSorted.sort((a, b) => {
      if (a.date_time <= b.date_time) {
        return 1;
      }

      if (a.date_time >= b.date_time) {
        return -1;
      }

      return 0;
    });

    return logCablesSorted;
  }, []);

  const handleLogFileDataFormatted = useCallback(data => {
    const logStatusData: ILogStatusDataDTO[] = data[0].map((logCable: any) => {
      const routesFormatted: string[] = logCable.routes.map((route: any) => {
        return `${
          route.previous_jumper ? route.previous_jumper : 'Central'
        } - ${route.jumper} (${route.status ? 'OK' : 'ROMPIDA'})`;
      });

      const {
        center,
        identifier,
        date_time,
        message,
        active,
        door_opened,
      } = logCable;

      const log: ILogStatusDataDTO = {
        center,
        identifier,
        date_time: formatDate({
          date: new Date(date_time),
          formatText: 'dd/MM/yy HH:mm:ss',
        }),
        message,
        active: active ? 'Ativo' : 'Desativado',
        door_status: door_opened ? 'Aberta' : 'Fechada',
        routes: routesFormatted.join(' | '),
      };

      return log;
    });

    const logUserActionData: ILogUserActionDataDTO[] = data[1].map(
      (logUserAction: any) => {
        const { cable, date_time, message, author } = logUserAction;

        const log: ILogUserActionDataDTO = {
          center: cable.center,
          identifier: cable.identifier,
          date_time: formatDate({
            date: new Date(date_time),
            formatText: 'dd/MM/yy HH:mm:ss',
          }),
          message,
          user: `${author.name} - ${author.email}`,
        };

        return log;
      },
    );

    setLogFileData([logStatusData, logUserActionData]);
  }, []);

  const handleCreateReport = () => {
    if (!logFileData) {
      return;
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const ws = utils.json_to_sheet(logFileData[0]);

    const ws2 = utils.json_to_sheet(logFileData[1]);

    const wscols1 = [
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 70 },
      { wch: 15 },
      { wch: 15 },
      { wch: 60 },
    ];

    const wscols2 = [
      { wch: 20 },
      { wch: 10 },
      { wch: 15 },
      { wch: 70 },
      { wch: 40 },
    ];

    if (logFileData[0].length > 0) {
      ws['!cols'] = wscols1;

      ws.A1.v = 'Central';
      ws.B1.v = 'Identificador';
      ws.C1.v = 'Data e Hora';
      ws.D1.v = 'Mensagem';
      ws.E1.v = 'Status do Sistema';
      ws.F1.v = 'Status da Porta';
      ws.G1.v = 'Rotas e seus Status';
    }

    if (logFileData[1].length > 0) {
      ws2['!cols'] = wscols2;

      ws2.A1.v = 'Central';
      ws2.B1.v = 'Identificador';
      ws2.C1.v = 'Data e Hora';
      ws2.D1.v = 'Mensagem';
      ws2.E1.v = 'Usuário que Atuou';
    }

    const wb = {
      Sheets: { status: ws, usuarios: ws2 },
      SheetNames: ['status', 'usuarios'],
    };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `status_sistemas_${String(getUnixTime(new Date()))}${fileExtension}`,
    );
  };

  const handleSubmitLogQuery = useCallback(
    (data: LogQueryFormData) => {
      const { cableId, period, type } = data;

      const initialDate = subDays(new Date(), Number(period));
      const endDate = new Date();

      api
        .post('/logs/cables', {
          cableId: cableId !== 'all' ? cableId : undefined,
          initialDate,
          endDate,
          isAlive: false,
        })
        .then(response => {
          setLogData(handleLogDataFormatted(response.data, type));
          // handleCreateReport('initialDate', 'endDate');
          handleLogFileDataFormatted(response.data);
        });
    },
    [handleLogDataFormatted, handleLogFileDataFormatted],
  );

  useEffect(() => {
    if (currentCable) {
      handleSubmitLogQuery({
        cableId: currentCable.id,
        period: '3',
        type: 'all',
      });
    } else {
      handleSubmitLogQuery({ cableId: 'all', period: '3', type: 'all' });
    }
  }, [handleSubmitLogQuery, currentCable]);

  const logDataFormatted: ILogDataFormatted[] = useMemo(() => {
    const logDataFormattedDirty: ILogDataFormatted[][] = logData.map(log => {
      const { center, message, user_name, date_time, type } = log;
      // eslint-disable-next-line no-nested-ternary
      const date = isToday(new Date(date_time))
        ? 'Hoje'
        : isYesterday(new Date(date_time))
        ? 'Ontem'
        : formatDate({ date: new Date(date_time), formatText: 'dd/MM/yy' });

      const logFormatted: ILogDataFormatted[] = message.split('\n').map(m => {
        const title: string[] = [];
        let logType: ILogType = {} as ILogType;

        if (center && user_name) {
          title.push(user_name);
          title.push(center);
          logType = { type: 'user', value: getInitialNameLetters(user_name) };
        } else if (user_name) {
          title.push(user_name);
          logType = { type: 'user', value: getInitialNameLetters(user_name) };
        } else if (center) {
          title.push(center);
          let valueLogType;
          if (
            m.includes('normalizado') ||
            m.includes('Porta do armário fechada') ||
            m.includes('O sistema está conectado na energia.')
          ) {
            valueLogType = 'checkIcon';
          } else if (m.includes('sem sinal')) {
            valueLogType = 'helpIcon';
          } else {
            valueLogType = 'xIcon';
          }
          logType = { type, value: valueLogType };
        }

        return {
          title,
          message: m,
          date,
          time: formatDate({
            date: new Date(date_time),
            formatText: 'HH:mm',
          }),
          logType,
        };
      });

      return logFormatted;
    });

    return logDataFormattedDirty
      .flat()
      .filter(
        log =>
          log.logType.type === 'all' ||
          log.logType.type === 'user' ||
          log.logType.type === 'center' ||
          (log.logType.type === 'ok' && log.message.includes('normalizado')) ||
          (log.logType.type === 'caution' &&
            log.message.includes('sem sinal')) ||
          (log.logType.type === 'rupture' &&
            log.message.includes('Rompimento')) ||
          (log.logType.type === 'battery' && log.message.includes('bateria')) ||
          (log.logType.type === 'plug' && log.message.includes('energia')) ||
          (log.logType.type === 'opened' &&
            log.message.includes('armário aberta')) ||
          (log.logType.type === 'closed' &&
            log.message.includes('armário fechada')),
      );
  }, [logData]);

  const cablesOptions: IOption[] = useMemo(() => {
    return [{ value: 'all', label: 'Todos' }].concat(
      cablesList.map(cable => {
        return { value: cable.id, label: cable.center };
      }),
    );
  }, [cablesList]);

  const periodOptions: IOption[] = [
    { value: '3', label: 'Últimos 3 dias' },
    { value: '5', label: 'Últimos 5 dias' },
    { value: '7', label: 'Últimos 7 dias' },
    { value: '10', label: 'Últimos 10 dias' },
    { value: '15', label: 'Últimos 15 dias' },
    { value: '30', label: 'Últimos 30 dias' },
    { value: '60', label: 'Últimos 60 dias' },
    { value: '90', label: 'Últimos 90 dias' },
  ];

  return (
    <Container>
      <Content>
        <Disclaimer>
          <div>
            <h3>Atividade geral</h3>
            <RiCloseFill onClick={() => close()} />
          </div>
          <p>
            Todas as atividades das rotas cadastradas centralizadas no mesmo
            lugar, começando pela mais recente.
          </p>
        </Disclaimer>
        <Filter>
          <Form onSubmit={handleSubmitLogQuery}>
            <div>
              <p>Equipamento:</p>
              <Select
                name="cableId"
                defaultOption={{
                  label: currentCable ? currentCable.center : 'Todos',
                  value: currentCable ? currentCable.id : 'all',
                }}
                options={cablesOptions}
                styles={{}}
              />
            </div>
            <div>
              <p>Tipo de relatório:</p>
              <Select
                name="type"
                defaultOption={{
                  label: 'Todos',
                  value: 'all',
                }}
                options={[
                  { value: 'all', label: 'Todos' },
                  { value: 'user', label: 'Usuários' },
                  { value: 'center', label: 'Equipamentos' },
                  { value: 'ok', label: 'Normalizados' },
                  { value: 'caution', label: 'Sem sinal' },
                  { value: 'rupture', label: 'Rompidos' },
                  { value: 'battery', label: 'Na bateria' },
                  { value: 'plug', label: 'Na energia' },
                  { value: 'opened', label: 'Porta aberta' },
                  { value: 'closed', label: 'Porta fechada' },
                ]}
                styles={{}}
              />
            </div>
            <div>
              <p>Período:</p>
              <Select
                name="period"
                defaultOption={{ value: '3', label: 'Últimos 3 dias' }}
                options={periodOptions}
                styles={{}}
              />
            </div>
            <Button type="submit">Buscar</Button>
            <div>
              <CgSoftwareDownload size={20} onClick={handleCreateReport} />
            </div>
          </Form>
        </Filter>
        <LogList>
          {logDataFormatted.length > 0 ? (
            logDataFormatted.map((log, i, a) => (
              <>
                {((i > 0 && a[i - 1].date !== log.date) || i === 0) && (
                  <>
                    {i !== 0 && <hr />}
                    <h5>{log.date}</h5>
                  </>
                )}

                <LogRegister>
                  {log.logType.type === 'user' ? (
                    <UserAvatar>{log.logType.value}</UserAvatar>
                  ) : (
                    (log.logType.value === 'checkIcon' && (
                      <CheckCircleIcon fill="#35C97F" />
                    )) ||
                    (log.logType.value === 'helpIcon' && (
                      <HelpCircleIcon fill="#FFDD33" />
                    )) ||
                    (log.logType.value === 'xIcon' && (
                      <XCircleIcon fill="#FB1F44" />
                    ))
                  )}
                  <LogDescription>
                    <small>{log.time}</small>
                    <strong>{log.title[0]}</strong>
                    {log.title[1] && (
                      <>
                        <span> editou a central </span>
                        <strong>{log.title[1]}</strong>
                      </>
                    )}
                    <p>{log.message}</p>
                  </LogDescription>
                </LogRegister>
              </>
            ))
          ) : (
            <NoLogMessage>
              <h4>Nenhum registro encontrado</h4>
            </NoLogMessage>
          )}
        </LogList>
      </Content>
    </Container>
  );
};

export default Log;
