import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

import { Container, Header, CardSignUp, CardSignUpFooter } from './styles';

import logoImg from '../../assets/logo_login.png';
import { ReactComponent as EyeIcon } from '../../assets/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/eye-off.svg';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string()
            .email('Precisa ser um e-mail válido')
            .required('O e-mail é obrigatória'),
          password: Yup.string()
            .required('Senha obrigatória')
            .min(6, 'Mínimo de 6 caracteres'),
        });

        await schema.validate(data, { abortEarly: false });

        const { name, email, password } = data;
        const alarm_type = 1;
        const token = localStorage.getItem('@IMCable:validationToken');

        await api.post('/users', { name, email, password, alarm_type, token });

        addToast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Pode seguir com o login.',
          type: 'success',
        });

        history.push('/');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          title: 'Falha ao realizar o cadastro.',
          description: `Erro: ${err.response.data.message}`,
          type: 'error',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Header src={logoImg} alt="Logo" />
      <CardSignUp>
        <h2>Cadastro</h2>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div>
            <Input label="Nome completo" name="name" />
            <Input label="E-mail" name="email" type="email" />
            <Input
              label="Senha"
              name="password"
              type={passwordVisibility ? 'text' : 'password'}
              icon={passwordVisibility ? EyeOffIcon : EyeIcon}
              togglePassword={() => setPasswordVisibility(!passwordVisibility)}
            />
          </div>
          <Button type="submit">Concluir cadastro</Button>
        </Form>
      </CardSignUp>
      <CardSignUpFooter>
        <button type="button" onClick={() => history.push('/')}>
          Já possui cadastro? Entre
        </button>
      </CardSignUpFooter>
    </Container>
  );
};

export default SignUp;
