import React, { useState } from 'react';
import { MdLocationOn } from 'react-icons/md';
import { ChildComponentProps } from 'google-map-react';

import { Container, InfoWindow } from './styles';
import IMarkerDTO from '../../../dtos/IMarkerDTO';

interface MarkerProps extends ChildComponentProps {
  marker: IMarkerDTO;
  label: string;
  status: number;
  zoom(marker: IMarkerDTO): void;
  selected: boolean;
}

const Marker: React.FC<MarkerProps> = ({
  marker,
  label,
  status,
  zoom,
  selected,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  return (
    <Container
      selected={selected}
      onMouseOver={() => setShowInfoWindow(true)}
      onMouseLeave={() => setShowInfoWindow(false)}
      onClick={() => zoom(marker)}
      status={status}
    >
      {showInfoWindow && (
        <InfoWindow>
          <div />
          <p>{label}</p>
        </InfoWindow>
      )}
      <MdLocationOn size={35} />
    </Container>
  );
};

export default Marker;
