import styled, { css } from 'styled-components';
// import { Link } from 'react-router-dom';

interface ContainerProps {
  active?: boolean;
}

export const Container = styled.div`
  background: #fff;
  position: absolute;
  min-width: 160px;
  width: max-content;

  border: 1px solid var(--bg3);
  box-shadow: 0px 1px 16px #02142329;
`;

export const Option = styled.div<ContainerProps>`
  font-size: 14px;
  color: var(--support1);
  text-decoration: none;
  padding: var(--space15);
  display: block;
  transition-duration: 0.3s;

  & + a {
    border-top: 1px solid var(--bg1);
  }
  &:hover {
    background: var(--bg1);
    color: var(--support1);
  }

  ${props =>
    props.active
      ? css`
          color: var(--action2);
        `
      : css``}
`;
