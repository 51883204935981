import React from 'react';

import Button from '../Button';

import { Container } from './styles';

interface PopUpMessageProps {
  data: {
    title: string;
    description: string;
  };
  action(confirmation: boolean): void;
}

const PopUpMessage: React.FC<PopUpMessageProps> = ({ data, action }) => {
  return (
    <Container>
      <div>
        <h2>{data.title}</h2>
        <p>{data.description}</p>
        <div>
          <Button type="button" onClick={() => action(false)}>
            Cancelar
          </Button>
          <Button type="submit" onClick={() => action(true)}>
            Confirmar
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default PopUpMessage;
