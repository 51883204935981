import React from 'react';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from 'react-google-places-autocomplete';
import { OptionTypeBase } from 'react-select';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
// import AutoComplete from 'react-google-autocomplete';

import { useToast } from '../../hooks/toast';

import Input from '../Input';
import Button from '../Button';
import Select from '../Select';

import ICableDataDTO2 from '../../dtos/ICableDataDTO2';
import { Container, Content, HorizontalLine, Center, Location } from './styles';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';

interface CardEditionProps {
  cable: ICableDataDTO2;
  close(): void;
  // closeParentCard(): void;
  updateDashboard(): void;
}

interface ICardEditionData {
  id: string;
  center: string;
  locations: string[];
  jumpers: string[];
  previous_jumpers: (string | null)[];
}

interface ILocationsTemporaryData {
  previousJumper: string | null;
  jumper: string;
  address: string;
}

interface CardEditionFormData {
  center: string;
  center_nickname: string | null;
  routeA: string;
  routeB: string;
  routeC: string;
  routeD: string;
  previousA: string;
  previousB: string;
  previousC: string;
  previousD: string;
  serialnumber: string;
}

interface CardEditionResult {
  id: string;
  center: string;
  locations: string[];
  previous_jumpers: string[];
  lat: number;
  lng: number;
}

interface IOption {
  value: string;
  label: string;
}

const CardEdition: React.FC<CardEditionProps> = ({
  cable,
  close,
  updateDashboard,
}) => {
  const [
    cardEditionData,
    setCardEditionData,
  ] = useState<ICardEditionData | null>(null);
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [defaultCenter, setDefaultCenter] = useState<string>('');
  const [centerValue, setCenterValue] = useState<OptionTypeBase | null>(null);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    const locationsTemporary: ILocationsTemporaryData[] = [];

    cable.alarmed_locations.forEach(alarmed => {
      const { jumper, address } = alarmed.to;
      locationsTemporary.push({
        previousJumper: alarmed.from.jumper,
        jumper,
        address,
      });
    });

    cable.ok_locations.forEach(alarmed => {
      const { jumper, address } = alarmed.to;
      locationsTemporary.push({
        previousJumper: alarmed.from.jumper,
        jumper,
        address,
      });
    });

    locationsTemporary.sort((a, b) => {
      if (a.jumper < b.jumper) {
        return -1;
      }
      if (a.jumper > b.jumper) {
        return 1;
      }
      return 0;
    });

    setCardEditionData({
      id: cable.id,
      center: cable.center,
      locations: locationsTemporary.map(l => {
        return l.address;
      }),
      jumpers: locationsTemporary.map(l => {
        return l.jumper;
      }),
      previous_jumpers: locationsTemporary.map(l => {
        return l.previousJumper;
      }),
    });

    const centerEdit = cable?.center.split('-') || [];

    if (centerEdit[1]) {
      centerEdit.shift();
    }
    setDefaultCenter(centerEdit.join('-'));
  }, [cable]);

  // Resgatando os serial numbers disponíveis
  useEffect(() => {
    api.get<string[]>('/cables/serialNumber').then(response => {
      setSerialNumbers(response.data);
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: CardEditionFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // center: Yup.string().required('Endereço da Central obrigatório'),
          routeA: Yup.string().required('Referência A obrigatória'),
          routeB: Yup.string().required('Referência B obrigatória'),
          routeC: Yup.string().required('Referência C obrigatória'),
          routeD: Yup.string().required('Referência D obrigatória'),
          previousA: Yup.string()
            .required('Ponto anterior inválido')
            .matches(/(Central|B|C|D)/),
          previousB: Yup.string()
            .required('Ponto anterior inválido')
            .matches(/(Central|A|C|D)/),
          previousC: Yup.string()
            .required('Ponto anterior inválido')
            .matches(/(Central|A|B|D)/),
          previousD: Yup.string()
            .required('Ponto anterior inválido')
            .matches(/(Central|A|B|C)/),
          serialnumber: Yup.string().required().min(8).max(8),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          center_nickname,
          routeA,
          routeB,
          routeC,
          routeD,
          previousA,
          previousB,
          previousC,
          previousD,
          serialnumber,
        } = data;

        // console.log(center_nickname);

        let centerModified;
        let latLng;

        if (centerValue?.label) {
          centerModified = center_nickname
            ? `${center_nickname.trim()} - ${centerValue.label}`
            : centerValue.label;

          latLng = await geocodeByAddress(centerValue.label);
        } else {
          const centerEdit = cardEditionData?.center.split('-') || [];

          if (centerEdit[1]) {
            centerEdit.shift();
          }

          centerModified = center_nickname
            ? `${center_nickname.trim()} - ${centerEdit.join('-')}`
            : centerEdit.join('-');

          latLng = await geocodeByAddress(centerEdit.join('-'));
        }

        const lat = latLng[0].geometry.location.lat();
        const lng = latLng[0].geometry.location.lng();

        const cablesLocation: CardEditionResult[] = [
          {
            id: cable.id,
            center: centerModified,
            locations: [routeA, routeB, routeC, routeD],
            previous_jumpers: [previousA, previousB, previousC, previousD],
            lat,
            lng,
          },
        ];

        api
          .post('/cables/location', { cablesLocation })
          .then(() => {
            addToast({
              title: 'Referências atualizadas!',
              type: 'success',
            });
            if (cable.identifier !== serialnumber) {
              api
                .patch('/cables/serialNumber', {
                  serial_number: `${serialnumber}`,
                  cable_id: cable.id,
                })
                .then(() => {
                  addToast({
                    title: 'Número de série atualizado!',
                    type: 'success',
                  });
                  updateDashboard();
                  close();
                })
                .catch(err => {
                  addToast({
                    title: `Falha ao atualizar o número de série.`,
                    description: `Erro: ${err.response.data.message}`,
                    type: 'error',
                  });
                });
            } else {
              updateDashboard();
              close();
            }
          })
          .catch(err => {
            addToast({
              title: `Falha ao atualizar as referências.`,
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: `Falha ao atualizar as referências.`,
          description: `Erro: ${err.response.data.message}`,
          type: 'error',
        });
      }
    },
    [
      close,
      cable,
      updateDashboard,
      addToast,
      centerValue,
      cardEditionData?.center,
    ],
  );

  const previousJumpersOptions: IOption[] = [
    { value: 'Central', label: 'Central' },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];

  const serialNumbersOptions: IOption[] = useMemo(() => {
    const options: IOption[] = [
      { value: cable.identifier, label: cable.identifier.substring(4) },
    ];
    serialNumbers.forEach(sn => {
      options.push({ value: sn, label: sn.substring(4) });
    });

    return options;
  }, [serialNumbers, cable]);

  const previousJumpersStyles = {
    // option: () => ({
    //   // border: '1px solid tomato',
    // }),
    // control: () => ({
    //   color: '#b2b2b2',
    //   background: '#f2f2f2',
    //   padding: '0.75rem',
    //   border: 0,
    //   width: '120px',
    //   height: '42px',
    // }),
    // dropdownIndicator: () => ({
    //   // margin: '0px',
    //   // padding: '0px',
    // }),
    // valueContainer: () => ({
    //   color: '#20e48c',
    //   paddingRight: '3rem',
    // }),
  };

  const serialNumbersStyles = {
    option: () => ({
      // border: '1px solid tomato',
    }),
    control: () => ({
      background: '#fff',
      padding: '0 15px',
      border: '1px solid var(--bg2)',
      // width: '100px',
    }),
    dropdownIndicator: () => ({
      // margin: '0px',
      // padding: '0px',
      color: 'tomato',
    }),
    indicatorsContainer: () => ({
      margin: '0px',
      padding: '0px',
      // color: 'tomato',
      background: 'green',
      height: 0,
    }),
    valueContainer: () => ({
      // width: 'fit-content',
      background: 'red',
      height: 0,
      // color: '#20e48c',
      // paddingRight: '3rem',
    }),
    // container: () => ({

    // }),
  };

  // const lett = useCallback(async () => {
  //   if (centerValue) {
  //     const latLng = await geocodeByAddress(centerValue.label);
  //     console.log(latLng[0].geometry.location.lat());
  //     console.log(latLng[0].geometry.location.lng());
  //   }
  // }, [centerValue]);

  // useEffect(() => {
  //   console.log(centerValue);
  //   lett();
  // }, [centerValue, lett]);

  return (
    <Container>
      <Content>
        <div>
          <h2>Editar informações</h2>
        </div>
        <HorizontalLine>
          <div />
        </HorizontalLine>
        {cardEditionData && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div>
              <h4>Central</h4>
              <Center>
                <Select
                  name="serialnumber"
                  defaultOption={{
                    label: cable.identifier.substring(4),
                    value: cable.identifier,
                  }}
                  options={serialNumbersOptions}
                  styles={serialNumbersStyles}
                />

                <div>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      name: 'center',
                      isClearable: true,
                      value: centerValue,
                      onChange: val => setCenterValue(val),
                      defaultInputValue: defaultCenter,
                    }}
                    // selectProps={{
                    //   value: centerValue,
                    //   onChange: setCenterValue,
                    // }}
                    apiKey="AIzaSyDgdP9hr2S_ML0pKUJ9A6E9jB2W3EDKxk4"
                  />

                  <Input
                    name="center_nickname"
                    defaultInputValue={
                      cardEditionData.center.split('-')[1]
                        ? cardEditionData.center.split('-')[0]
                        : ''
                    }
                    label="Referência (Ex: A15)"
                  />
                </div>
                {/* <PlacesAutocomplete value={centerValue} onChange={setCenterValue} onSelect={() => {console.log('test')})} /> */}

                {/* <AutoComplete
                  apiKey="AIzaSyDgdP9hr2S_ML0pKUJ9A6E9jB2W3EDKxk4"
                  onPlaceSelected={place => console.log(place)}
                /> */}

                {/* <Input
                  name="center"
                  defaultInputValue={cardEditionData.center}
                  label="Nome da central"
                /> */}
              </Center>
            </div>
            <div>
              <h4>Rotas</h4>
              {cardEditionData.previous_jumpers.map((previous, index) => (
                <Location key={`${index + 0}`}>
                  <Select
                    defaultOption={{
                      label: previous === null ? 'Central' : previous,
                      value: previous === null ? 'Central' : previous,
                    }}
                    name={`previous${cardEditionData.jumpers[index]}`}
                    options={previousJumpersOptions.filter(
                      o => o.label !== cardEditionData.jumpers[index],
                    )}
                    styles={previousJumpersStyles}
                  />
                  <MdArrowForward />
                  <Input
                    name={`route${cardEditionData.jumpers[index]}`}
                    defaultInputValue={cardEditionData.locations[index]}
                    label={`Referência do ponto ${cardEditionData.jumpers[index]}`}
                  />
                </Location>
              ))}
            </div>
            <div>
              <Button type="button" onClick={() => close()}>
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </Form>
        )}
      </Content>
    </Container>
  );
};

export default CardEdition;
