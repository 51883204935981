export default function getInitialNameLetters(name: string): string {
  const nameSplitted = name.split(' ');

  if (nameSplitted.length === 1) {
    return name.substr(0, 1).toUpperCase();
  }

  return `${nameSplitted[0].substr(0, 1).toUpperCase()}${nameSplitted[
    nameSplitted.length - 1
  ]
    .substr(0, 1)
    .toUpperCase()}`;
}
