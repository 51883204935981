import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  InputHTMLAttributes,
} from 'react';
import { useField } from '@unform/core';

import { ReactComponent as AlertCircle } from '../../assets/alert-circle.svg';

import { Container, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  defaultInputValue?: string;
  label: string;
  icon?: React.ComponentType<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  togglePassword?(): void;
}

const Input: React.FC<IInputProps> = ({
  name,
  label,
  icon: Icon,
  defaultInputValue,
  togglePassword,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });

    setIsFilled(!!defaultInputValue);
  }, [registerField, fieldName, defaultInputValue]);

  return (
    <>
      <Container isErrored={!!error} isFocused={isFocused} isFilled={isFilled}>
        <input
          ref={inputRef}
          defaultValue={defaultInputValue}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
          required
        />
        <span>{label}</span>

        {error ? (
          <>
            <AlertCircle /> <Error title={error} />
          </>
        ) : (
          ((togglePassword && !!inputRef.current?.value) || isFocused) &&
          Icon && (
            <Icon
              onClick={() => {
                togglePassword && togglePassword();
              }}
            />
          )
        )}
      </Container>
    </>
  );
};

export default Input;
