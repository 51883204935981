import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.img`
  height: 6rem;
  margin: 4rem 0;
`;

export const CardSignUp = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  max-width: 450px;
  width: 100%;

  form {
    width: 100%;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  div input {
    margin-bottom: 0.75rem;
  }

  button {
    margin-top: 0.75rem;
  }
`;

export const CardSignUpFooter = styled.div`
  background: var(--bg2);
  text-align: center;
  padding: 1.5rem 0;
  max-width: 450px;
  width: 100%;

  button {
    font-size: 12px;
    border: 0;
    background: transparent;
    text-decoration: underline;
  }
`;
