import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100%; */
  display: none;
  position: relative;

  div:first-of-type {
    background: transparent;
    border: 7px solid transparent;
    border-right-color: var(--support1);
    position: absolute;
    /* top: 50%; */
    /* left: 51px; */
    transform: translate(0, -50%);
  }

  div:nth-child(2) {
    max-width: 300px;
    display: flex;
    padding: 10px 15px;
    position: absolute;
    background: var(--support1);
    /* top: 50%; */
    /* left: 65px; */
    left: 14px;
    transform: translate(0, -50%);
    width: max-content;

    span {
      color: var(--bg2);
      font-size: 11px;
    }
  }
`;
