import styled, { css } from 'styled-components';

interface ContainerProps {
  type: 'button' | 'submit' | 'reset';
}

const buttonTypes = {
  submit: css`
    background: var(--action2);
    color: #fff;

    &:hover {
      background: var(--action1);
    }
  `,
  button: css`
    background: var(--bg2);

    &:hover {
      background: var(--bg3);
    }
  `,

  reset: css``,
};

export const Container = styled.button<ContainerProps>`
  font-size: 14px;
  width: 100%;
  padding: 12px 0;
  border: 0;
  transition-duration: 0.4s;

  ${props => buttonTypes[props.type]}
`;
