import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

interface FormatDateParams {
  date: Date;
  formatText: string;
}

export default function formatDate({
  date,
  formatText,
}: FormatDateParams): string {
  return format(date, formatText, { locale: ptBR });
}
