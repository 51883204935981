import React, { useState, useRef, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Header,
  CardSignIn,
  CardSignInFooter,
  PopUpValidationToken,
} from './styles';

import logoImg from '../../assets/logo_login.png';

import { ReactComponent as KeyIcon } from '../../assets/key.svg';
import { ReactComponent as EyeIcon } from '../../assets/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/eye-off.svg';

import getValidationErrors from '../../utils/getValidationErrors';

interface SignInFormData {
  email: string;
  password: string;
}

interface TokenFormData {
  token: string;
}

const SignIn: React.FC = () => {
  const [showTokenPopUp, setShowTokenPopUp] = useState(false);
  const formLoginRef = useRef<FormHandles>(null);
  const formTokenRef = useRef<FormHandles>(null);
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmitLogin = useCallback(
    async (data: SignInFormData) => {
      try {
        formLoginRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            // .min(6, 'Mínimo de 6 caracteres')
            .required('Senha obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = data;

        await signIn({ email, password });

        history.push('/dashboard');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formLoginRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: 'Falha ao fazer login.',
          description: `Erro: ${err.response.data.message}`,
          type: 'error',
        });
      }
    },
    [signIn, history, addToast],
  );

  const handleSubmitToken = useCallback(
    async (data: TokenFormData) => {
      try {
        formTokenRef.current?.setErrors({});

        const schema = Yup.object().shape({
          token: Yup.string()
            .required('Token obrigatório')
            .min(8, 'Tamanho obrigatório de 8 caracteres')
            .max(8, 'Tamanho obrigatório de 8 caracteres'),
        });

        await schema.validate(data, { abortEarly: false });

        const { token } = data;

        await api.get(`/validationTokens/${token}`);

        localStorage.setItem('@IMCable:validationToken', token);

        history.push('/signup');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formTokenRef.current?.setErrors(errors);

          return;
        }
        addToast({
          title: 'Falha com o token.',
          description: `Erro: ${err.response.data.message}`,
          type: 'error',
        });
      }
    },
    [addToast, history],
  );

  return (
    <>
      <Container>
        <Header src={logoImg} alt="Logo" />
        <CardSignIn>
          <h2>Login</h2>
          <Form ref={formLoginRef} onSubmit={handleSubmitLogin}>
            {/* <div> */}

            <Input name="email" label="E-mail" type="email" />
            <Input
              name="password"
              label="Senha"
              type={passwordVisibility ? 'text' : 'password'}
              icon={passwordVisibility ? EyeOffIcon : EyeIcon}
              togglePassword={() => setPasswordVisibility(!passwordVisibility)}
            />
            {/* <Link to="/">Esqueceu sua senha?</Link> */}
            {/* </div> */}
            {/* <div>
            <input type="checkbox" name="remember-user" id="false" value="" />
            <span>Lembrar de mim</span>
          </div> */}
            <Button type="submit">Entrar</Button>
          </Form>
        </CardSignIn>
        <CardSignInFooter>
          <button type="button" onClick={() => setShowTokenPopUp(true)}>
            Iniciar cadastro
          </button>
        </CardSignInFooter>
      </Container>
      {showTokenPopUp && (
        <PopUpValidationToken>
          <div>
            <h2>Possui um token?</h2>
            <Form ref={formTokenRef} onSubmit={handleSubmitToken}>
              <div>
                <Input name="token" label="Insira seu token" icon={KeyIcon} />
              </div>
              <div>
                <Button type="button" onClick={() => setShowTokenPopUp(false)}>
                  Cancelar
                </Button>
                <Button type="submit">Continuar</Button>
              </div>
            </Form>
          </div>
        </PopUpValidationToken>
      )}
    </>
  );
};

export default SignIn;
