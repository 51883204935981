import styled, { css } from 'styled-components';

interface StateToggleProps {
  deactivate: boolean;
}

interface ContainerProps {
  active: boolean;
}

interface BolaoProps {
  statusColor: number;
}

const bolaoColors = [
  'var(--success2)',
  'var(--problem2)',
  'var(--warning2)',
  'var(--bg2)',
];

export const Container = styled.div<ContainerProps>`
  background: #fff;
  min-width: 280px;
  /* min-height: 100px; */
  display: flex;
  transition-duration: 0.5s;
  position: relative;

  ${props =>
    props.active
      ? css`
          box-shadow: 0px 1px 6px #02142329;

          cursor: pointer;
          &:hover {
            box-shadow: 0px 1px 16px #02142329;
          }
        `
      : css`
          color: var(--support-text);
        `}
`;

export const Info = styled.div`
  padding: var(--space15) 0;
  border-right: 1px solid var(--bg2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > div {
    margin: 0 5px;
  }

  svg {
    fill: var(--support-text);
    height: 16px;
    width: 16px;
  }

  > div {
    margin-top: var(--space15);
  }

  > div:first-of-type {
    /* margin: 0 0 auto; */
    margin-top: 0;
  }

  > div:hover > div {
    transition-duration: 0.5s;
    display: block;
    top: -10px;

    right: -15px;

    /* &:first-of-type {
      top: -70px;
    } */

    span {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  min-width: 250px;
  padding: var(--space15);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  span:first-child {
    font-size: 12px;
    font-weight: bold;
  }

  h2 {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  p {
    padding-bottom: 3px;
    color: var(--problem2);
  }

  span {
    color: var(--support-text);
    padding-bottom: 3px;
  }
`;

export const Tags = styled.div`
  display: flex;
  /* margin-top: 5px; */

  p {
    font-size: 12px;
    font-weight: bold;
    color: var(--support-text);
    background: var(--bg2);
    padding: 3px 5px;
  }

  p + p {
    margin-left: 10px;
  }
`;

export const State = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-top: var(--space10);

    p {
      color: var(--support-text);
      padding: 0;
      margin-right: var(--space10);
    }
  }
`;

export const Bolao = styled.div<BolaoProps>`
  height: var(--space15);
  width: var(--space15);
  border-radius: 400000000000000000000000px;
  background: ${props => bolaoColors[props.statusColor]};
  box-shadow: 0px 0px 10px
    ${props =>
      props.statusColor === 3 ? '#fff' : bolaoColors[props.statusColor]};
  position: absolute;
  right: var(--space15);
`;

export const StateToggle = styled.div<StateToggleProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 32px;
  height: 16px;
  border-radius: 40000000000000000000000000000000000000000000000px;

  div {
    height: 11px;
    width: 11px;
    background: #fff;
    border-radius: 50%;
    margin: 0 3px;
  }

  ${props =>
    props.deactivate
      ? css`
          background: var(--support-text);
          div {
            margin-right: auto;
          }
        `
      : css`
          background: var(--action2);
          div {
            margin-left: auto;
          }
        `}
`;
