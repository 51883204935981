import styled from 'styled-components';

export const Container = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background: #fff;
  width: 860px;
  padding: var(--space30);

  form {
    > div {
      margin-top: var(--space30);
    }

    > div:last-child {
      display: flex;
      width: 50%;
      margin-left: auto;

      button {
        margin-left: var(--space30);
      }
    }
  }
`;

export const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;

  background: var(--bg1);
`;

export const Center = styled.div`
  margin-top: var(--space10);
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: var(--space30);

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: chocolate; */

    > div:nth-child(1) {
      width: 70%;
      margin-right: 20px;
      /* flex: 1; */
    }

    > div:nth-child(2) {
      width: 30%;
    }
  }
`;

export const Location = styled.div`
  margin-top: var(--space10);
  display: grid;
  grid-template-columns: 2fr 1fr 12fr;
  grid-gap: var(--space15);

  > svg {
    color: var(--support-text);
    height: 20px;
    width: 20px;
    margin: auto;
    display: block;
  }
`;
