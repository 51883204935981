import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.img`
  height: 6rem;
  margin: 4rem 0;
`;

export const CardSignIn = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  max-width: 450px;
  width: 100%;

  form {
    width: 100%;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  div {
    margin-bottom: 0.75rem;
  }

  button {
    margin-top: 0.75rem;
  }
`;

export const CardSignInFooter = styled.div`
  background: var(--bg2);
  text-align: center;
  padding: 1.5rem 0;
  max-width: 450px;
  width: 100%;

  button {
    font-size: 12px;
    border: 0;
    background: transparent;
    text-decoration: underline;
  }
`;

export const PopUpValidationToken = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    background: #fff;
    max-width: 450px;
    padding: 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 100%;

      > div {
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
      }

      button + button {
        margin-left: 1.5rem;
      }
    }
  }
`;
