import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { MdClose, MdRemove } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';

import { ToastMessage, useToast } from '../../../hooks/toast';

import {
  Container,
  VerticalLine,
  IconContainer,
  Text,
  CloseButton,
} from './styles';

interface ToastProps {
  message: ToastMessage;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style: object;
}

const icons = {
  alarmed: <MdClose size={25} />,
  success: <MdRemove size={25} />,
  error: <MdClose size={25} />,
  default: <MdRemove size={25} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  const handleCreateTimer = useCallback(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message]);

  useEffect(() => {
    if (message.type !== 'alarmed') {
      handleCreateTimer();
    }
  }, [handleCreateTimer, message.type]);

  return (
    <Container type={message.type} style={style}>
      <VerticalLine type={message.type}>
        <div />
      </VerticalLine>
      <IconContainer type={message.type}>{icons[message.type]}</IconContainer>
      <Text>
        <p>{message.title}</p>
        <span>{message.description}</span>
      </Text>
      <CloseButton onClick={() => removeToast(message.id)}>
        <RiCloseFill size={25} />
      </CloseButton>
    </Container>
  );
};

export default Toast;
