import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'DM Sans', sans-serif;
  }

  :root {
    /* Colors: */
    --success2: #35C97F;
    --attention_bg: #FFF2F4;
    --attention_border: #FFC9D2;
    --problem2: #FB1F44;
    --action_bg: #CCE6FF;
    --action2: #0081FF;
    --bg1: #F2F3F4;
    --bg2: #E6E7E8;
    --bg3: #C9CDD1;
    --support-text: #ABAFB2;
    --support_contrast2: #4D6880;
    --support_contrast1: #2F4A61;
    --support_contrast0: #0D2336;
    --support1: #021423;
    --warning2: #FFDD33;


    --success3: #4DD692;
    --success2: #35C97F;
    --success1: #25B06A;
    --problem3: #FF385A;
    --problem2: #FB1F44;
    --problem1: #E01033;
    --action3: #1A8EFF;
    --action2: #0081FF;
    --action1: #0074E6;
    --support3: #132A3D;
    --support2: #0A1F30;
    --support1: #021423;

    --space30: 1.5rem;
    --space15: 0.75rem;
    --space10: 0.5rem;

    --shadow-short: 0px 1px 6px #02142329;
    --shadow-large: 0px 1px 16px #02142329;
  }

  body {
    font-size: 16px;
    color: var(--support1);
    background: var(--bg1);
    -webkit-font-smoothing: antialiased;
  }

  div {
    /* padding: 0 1.5rem; */
  }

  h1 {
    font-size: 28px;
    line-height: 39px;
    font-weight: bold;
  }

  h2 {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
  }

  h3 {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
  }

  h4 {
    font-size: 18px;
    line-height: 25px;
    font-weight: normal;
  }

  h5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  h6 {
    font-size: 12px;
    line-height: 17px;
    font-weight: normal;
  }

  p, span, ol, ul {
    font-size: 14px;
  }

  button {
    cursor: pointer;
  }

  svg {
    display: block;
  }


`;
