import React from 'react';

import { Container, Option } from './styles';

interface DropDownOption {
  title: string;
  // link: string;
  active?: boolean;
  action(): void;
}

interface DropdownMenuProps {
  options: DropDownOption[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ options }) => {
  return (
    <Container>
      {options.map(({ title, active, action }) => (
        <Option onClick={action} active={active}>
          {title}
        </Option>
      ))}
    </Container>
  );
};

export default DropdownMenu;
