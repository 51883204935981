import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  ...rest
}) => {
  return (
    <Container type={type} {...rest}>
      {children}
    </Container>
  );
};

export default Button;
