import styled, { css } from 'styled-components';

interface PermissionProps {
  permission: boolean;
}

interface StateToggleProps {
  deactivate: boolean;
  permission: boolean;
}

interface RouteStateProps {
  type: 'alarmed' | 'ok';
}

interface HorizontalLineRouteProps {
  invisible: boolean;
}

const titleTypes = {
  alarmed: css`
    color: var(--problem2);
  `,

  ok: css`
    color: var(--action2);
  `,
};

const routeTypes = {
  alarmed: css`
    > span {
      border-left: 2px solid var(--problem2);
    }
    svg {
      fill: var(--support-text);
    }
  `,

  ok: css`
    > span {
      border-left: 2px solid var(--action2);
    }
    svg {
      fill: var(--action2);
    }
  `,
};

export const Container = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Content = styled.div`
  background: #fff;
  width: 860px;
  box-shadow: var(--shadow-large);
  display: grid;
  grid-template-columns: 75% 25%;

  > div {
    min-height: 50px;
    min-width: 50px;
  }
`;

export const Main = styled.div`
  background: #fff;
  padding: var(--space30) 0;
  overflow-y: scroll;
  max-height: 90vh;

  > div:first-child {
    padding: 0 var(--space30);

    span {
      color: var(--support-text);
      font-size: 12px;
    }

    span:last-child {
      font-size: inherit;
    }
  }

  > div:nth-child(2) {
    padding-left: var(--space30);
    padding-right: var(--space30);
  }

  > div + div {
    padding-top: var(--space30);
  }
`;

export const Routes = styled.div``;

export const RouteTitle = styled.div<RouteStateProps>`
  font-size: 18px;
  padding: 0 var(--space30) var(--space15);

  &:not(:nth-child(1)) {
    padding-top: var(--space30);
  }

  small {
    font-size: 14px;
    font-weight: bold;
    ${props => titleTypes[props.type]}
  }
`;

export const Route = styled.div<RouteStateProps>`
  background: var(--bg1);
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space30) 0;
  border: 0;

  ${props => routeTypes[props.type]}
  /* position: relative; */

  > span {
    font-size: 14px;
    display: block;
    flex: 1;
    padding-left: 5px;
    margin: 0 var(--space30);

    &:hover div {
      transition-duration: 0.5s;
      display: block;
    }
  }

  /* // GAMBIARRA para deixar o tooltip por cima do scroll
  svg + span:hover + div {
    transition-duration: 0.5s;
    display: block;

    left: 60%;
    position: absolute;
  } */

  svg {
    height: 16px;
    width: 16px;
  }
`;

export const HorizontalLineRoute = styled.div<HorizontalLineRouteProps>`
  /* & + div {
    padding: 0 30px;
  }
*/

  background: var(--bg1);
  width: 100%;

  div {
    ${props =>
      props.invisible
        ? css`
            background: var(--bg1);
          `
        : css`
            background: var(--bg3);
          `}
    margin: 0 var(--space30);
    height: 1px;
  }
`;

export const Handling = styled.div`
  display: flex;
  align-items: center;
  padding: var(--space30) var(--space30) 0;
  position: relative;
`;

export const HandlingStatus = styled.div`
  height: 42px;
  background: var(--bg1);
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-of-type {
    > div:first-of-type {
      &:hover {
        + div {
          display: flex;
        }
      }
    }
  }

  h4 {
    color: var(--support-text);
    font-size: 12px;
    margin: 0 var(--space15);
  }

  div:nth-child(3) {
    margin: 0 var(--space15);
    p {
      color: var(--support-text);
      font-size: 11px;
    }
  }

  button {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--action2);
    color: #fff;
    font-size: 14px;
    border: 0;
    padding: var(--space10) var(--space15);

    svg {
      margin-right: var(--space10);
      height: 20px;
      width: 20px;
      /* fill: #fff;  */
    }
  }
`;

export const UserTooltip = styled.div`
  min-width: fit-content;
  /* width: 100%; */
  position: absolute;
  bottom: 50px;
  align-items: center;
  background: #fff;
  padding: var(--space15);
  box-shadow: var(--shadow-large);
  transform: translate(-45%, 0);
  display: none;

  div:first-of-type {
    background: transparent;
    border: 7px solid transparent;
    border-top-color: #fff;
    position: absolute;
    top: 68px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  div:nth-child(2) {
    height: 45px;
    width: 45px;
    /* margin-right: var(--space15); */

    p {
      font-size: 20px;
    }
  }

  h3 + p {
    color: var(--support-text);
    font-size: 11px;
  }
`;

export const UserLetters = styled.div`
  height: 25px;
  width: 25px;
  background: var(--action2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;

  p {
    font-size: 12px;
  }
`;

export const HandlingIdentifier = styled.div`
  margin-left: var(--space30);
  color: var(--support-text);
  font-size: 14px;
  strong {
    color: var(--support1);
    font-weight: 500;
  }
`;

export const Maintenance = styled.div<PermissionProps>`
  padding: var(--space15) var(--space30) 0;
  display: flex;
  align-items: center;

  div {
    background: var(--bg1);
    height: 42px;
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      color: var(--support-text);
      padding: 0 var(--space15);
    }
  }

  button {
    font-size: 12px;
    color: #fff;
    background: var(--action2);
    height: 42px;
    padding: 0 var(--space15);
    border: 0;
    transition-duration: 0.2s;
    display: flex;
    align-items: center;

    svg {
      margin-right: var(--space10);
      height: 20px;
      width: 20px;
      fill: #fff;
    }

    ${props =>
      !props.permission
        ? css`
            opacity: 0.5;
            cursor: not-allowed;
          `
        : css`
            &:hover {
              background: var(--action1);
              transition-duration: 0.2s;
            }
          `}
  }
`;

export const Side = styled.div`
  background: var(--bg2);
  padding: var(--space30);

  > svg {
    color: #b2b2b2;
    width: 25px;
    height: 25px;
    margin-left: auto;
    display: block;
    cursor: pointer;
  }

  > div {
    margin: var(--space30) 0;
  }

  > div:first-of-type {
    div {
      background: var(--bg1);
      padding: var(--space10);
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
      }

      span {
        padding-left: 5px;
      }
    }

    div:nth-child(1),
    div:nth-child(2),
    div:nth-child(3) {
      cursor: pointer;
      /* display: none; */
      margin-bottom: var(--space15);
    }

    div:nth-child(4),
    div:nth-child(5),
    div:nth-child(6) {
      /* color: var(--support-text);
      fill: var(--support-text); */
      background: inherit;
      margin: 0;
      cursor: default;
    }
  }
`;

export const CardLog = styled.div``;

export const CardEditionOption = styled.div<PermissionProps>`
  ${props =>
    !props.permission
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

export const CardMap = styled.div`
  cursor: pointer;
`;

export const HorizontalLine = styled.div`
  div {
    background: var(--bg3);
    width: 100%;
    height: 1px;
  }
`;

export const State = styled.div`
  > div {
    display: flex;
    align-items: center;

    h4 {
      font-weight: 500;
      font-size: 20px;
      display: block;
      margin-right: var(--space10);
    }
  }

  p {
    color: var(--support-text);
    margin-top: var(--space10);
  }
`;

export const RequestStatus = styled.div`
  h4 {
    font-weight: 500;
    font-size: 20px;
    display: block;
    margin-top: var(--space10);

    strong {
      color: var(--support-text);
    }
  }
`;

export const StateToggle = styled.div<StateToggleProps>`
  /* position: relative; */

  display: flex;
  align-items: center;
  width: 32px;
  height: 16px;
  border-radius: 40000000000000000000000000000000000000000000000px;

  div {
    height: 11px;
    width: 11px;
    background: #fff;
    border-radius: 50%;
    margin: 0 3px;
  }

  ${props =>
    props.deactivate
      ? css`
          background: var(--support-text);
          div {
            margin-right: auto;
          }
        `
      : css`
          background: var(--action2);
          div {
            margin-left: auto;
          }
        `}

  ${props =>
    !props.permission
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;

export const Notes = styled.div`
  padding: var(--space30);

  h4 {
    margin-bottom: var(--space15);
  }

  form {
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      height: 45px;
      width: 45px;
      margin-right: var(--space15);

      p {
        font-size: 20px;
      }
    }

    > div:nth-child(2) {
      flex: 1;

      input {
        padding-right: var(--space15);
      }
    }

    button {
      width: 20%;
      margin-left: var(--space30);
    }
  }
`;

export const PreviousNote = styled.div`
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    height: 45px;
    width: 45px;
    margin-right: var(--space15);

    p {
      font-size: 20px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin-top: var(--space30);
    width: 100%;
    flex: 1;

    div {
      display: flex;
      align-items: center;

      span:nth-child(2),
      span:nth-child(3) {
        padding-left: var(--space10);
        border-left: 1px solid var(--support-text);
        margin-left: var(--space10);
        font-size: 12px;
        color: var(--support-text);
      }
    }

    textarea {
      font-size: 12px;
      background: #fff;
      width: 100%;
      max-height: 55px;
      padding: 13px 15px;
      /* border: 0; */
      margin-top: 10px;
      resize: none;
    }
  }
`;

export const UpdateNote = styled.div`
  border-left: 2px solid var(--action2);
  padding-left: var(--space10);
  span {
    font-size: 12px;
    color: var(--support-text);
  }
`;
