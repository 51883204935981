import React, { useCallback, useState, useRef } from 'react';
import { RiCloseFill, RiSignalWifiOffLine } from 'react-icons/ri';
import {
  TiBatteryFull,
  TiBatteryHigh,
  TiBatteryMid,
  TiBatteryLow,
} from 'react-icons/ti';
import {
  RiSignalWifiFill,
  RiSignalWifi1Fill,
  RiSignalWifi2Fill,
  RiSignalWifi3Fill,
} from 'react-icons/ri';

import { MdMap } from 'react-icons/md';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import ICableDataDTO2 from '../../dtos/ICableDataDTO2';
import IPopUpMessageDTO from '../../dtos/IPopUpMessageDTO';
import CardEdition from '../CardEdition';
import PopUpMessage from '../PopUpMessage';
import Log from '../Log';
import Map from '../MapContainer';

import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as PlugIcon } from '../../assets/plug.svg';
import { ReactComponent as DoorOpenedIcon } from '../../assets/door-opened.svg';
import { ReactComponent as DoorClosedIcon } from '../../assets/door-closed.svg';
import { ReactComponent as HistoryIcon } from '../../assets/history.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right.svg';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';

import getInitialNameLetters from '../../utils/getInitialNameLetters';
import getValidationErrors from '../../utils/getValidationErrors';
import formatDate from '../../utils/formatDate';

import {
  Container,
  Content,
  Main,
  Routes,
  RouteTitle,
  Route,
  HorizontalLineRoute,
  Maintenance,
  Handling,
  HandlingStatus,
  UserTooltip,
  UserLetters,
  HandlingIdentifier,
  RequestStatus,
  Side,
  CardLog,
  CardEditionOption,
  CardMap,
  HorizontalLine,
  State,
  StateToggle,
  Notes,
  PreviousNote,
} from './styles';
import { useAuth } from '../../hooks/auth';
import Input from '../Input';
import Button from '../Button';
import IMarkerDTO from '../../dtos/IMarkerDTO';

interface INoteFormData {
  text: string;
}

interface ICableMainData {
  id: string;
  center: string;
}

interface CardOpenedProps {
  cable: ICableDataDTO2;
  closeCard(): void;
  updateDashboard(): void;
  editPermission: boolean;
  cablesList: ICableMainData[];
  markersList: IMarkerDTO[];
  // cardUpdated: boolean;
}

interface ICableRequestedData {
  status: number;
  last_status_date: Date | null;
}

interface ICableRequestedStatus {
  status: 'online' | 'sem sinal';
  date: string;
}

const CardOpened: React.FC<CardOpenedProps> = ({
  cable,
  closeCard,
  updateDashboard,
  editPermission,
  cablesList,
  markersList,
}) => {
  const [
    popUpMessageUnderMaintenance,
    setPopUpMessageUnderMaintenance,
  ] = useState<IPopUpMessageDTO | null>(null);

  const [
    popUpMessageActivate,
    setPopUpMessageActivate,
  ] = useState<IPopUpMessageDTO | null>(null);

  const [
    popUpMessageHandling,
    setPopUpMessageHandling,
  ] = useState<IPopUpMessageDTO | null>(null);

  const [showCardEdition, setShowCardEdition] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [
    cableRequestedStatus,
    setCableRequestedStatus,
  ] = useState<ICableRequestedStatus>();

  const [showMap, setShowMap] = useState(false);

  const formNoteRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { user } = useAuth();

  const handlePopUpMessageUnderMaintenance = useCallback(() => {
    if (!editPermission) {
      return;
    }
    if (cable.under_maintenance) {
      setPopUpMessageUnderMaintenance({
        title: 'Confirma que esta unidade não está mais em manutenção?',
        description: 'As notificações e registros de log voltarão a ocorrer.',
      });
    } else {
      setPopUpMessageUnderMaintenance({
        title: 'Confirma que esta unidade irá para a manutenção?',
        description:
          'As notificações e registros de log serão interrompidas enquanto a unidade estiver em manutenção. Mas a sua mudança de status continuará sendo refletida no site.',
      });
    }
  }, [cable, setPopUpMessageUnderMaintenance, editPermission]);

  const handlePopUpMessageActivate = useCallback(() => {
    if (!editPermission) {
      return;
    }

    if (cable.active) {
      setPopUpMessageActivate({
        title: 'Deseja realmente desativar essa unidade?',
        description:
          'Essa ação irá inibir as notificações de mudança de status, tal como os seus registros de log. Enquanto a unidade estiver desativada, ela permanecerá na aba de desativados.',
      });
    } else {
      setPopUpMessageActivate({
        title: `Confirma que a unidade ${cable.center} será reativada?`,
        description:
          'As notificações, registros de log e mudança de status voltarão a ocorrer.',
      });
    }
  }, [cable, setPopUpMessageActivate, editPermission]);

  const handleUnderMaintenanceUpdate = useCallback(
    (confirmation: boolean) => {
      setPopUpMessageUnderMaintenance(null);

      if (confirmation) {
        const { under_maintenance } = cable;
        api
          .patch('/cables/underMaintenance', {
            cable_id: cable.id,
            under_maintenance: !under_maintenance,
          })
          .then(() => {
            addToast({
              title: !under_maintenance
                ? 'Unidade em manutenção!'
                : 'Unidade fora da manutenção!',
              type: 'default',
            });
            updateDashboard();
          })
          .catch(err => {
            addToast({
              title: `Falha ao ${!under_maintenance
                ? 'colocar em manutenção'
                : 'retirar da manutenção'
                }.`,
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
      }

      // closeCard();
    },
    [cable, updateDashboard, addToast],
  );

  const handleActiveUpdate = useCallback(
    (confirmation: boolean) => {
      setPopUpMessageActivate(null);

      if (confirmation) {
        const { active } = cable;
        api
          .patch('/cables/activate', {
            cable_id: cable.id,
            activate: !active,
          })
          .then(() => {
            addToast({
              title: !active
                ? 'Unidade ativada!' // Nao vai acontecer
                : 'Unidade desativada! Movida para a aba de desativados.',
              type: 'default',
            });
            updateDashboard();
          })
          .catch(err => {
            addToast({
              title: `Falha ao ${!active ? 'ativar a unidade' : 'desativar a unidade'
                }.`,
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
      }

      // closeCard();
    },
    [cable, updateDashboard, addToast],
  );

  const handleCloseCardEdition = useCallback(() => {
    setShowCardEdition(false);
  }, [setShowCardEdition]);

  const handleCloseCardLog = useCallback(() => {
    setShowLog(false);
  }, [setShowLog]);

  // const handleCloseCardOpened = useCallback(() => {
  //   closeCard();
  // }, [closeCard]);

  const handleCloseMap = useCallback(() => {
    setShowMap(false);
  }, []);

  const handlePopUpMessageHandling = useCallback(() => {
    if (!editPermission) {
      return;
    }

    if (cable.handling === 1) {
      setPopUpMessageHandling({
        title: 'Confirma o início do tratamento dessa unidade?',
        description: 'Um número de ordem será gerado automaticamente.',
      });
    } else {
      setPopUpMessageHandling({
        title: 'Confirma que esta unidade foi tratada?',
        description:
          'Caso não seja concluído o tramento em até 24horas, o sistema automaticamente irá considera-lo como tratado.',
      });
    }
  }, [editPermission, cable.handling]);

  const handleHandlingUpdate = useCallback(
    async (confirmation: boolean) => {
      setPopUpMessageHandling(null);

      if (confirmation) {
        api
          .put('/cables/handling', {
            cable_id: cable.id,
            handling: cable.handling + 1,
          })
          .then(() => {
            addToast({
              title:
                cable.handling === 1
                  ? 'Iniciado o tratamento!'
                  : 'Finalizado o tratamento!',
              type: 'default',
            });
            updateDashboard();
          })
          .catch(err => {
            addToast({
              title: `Falha ao ${cable.handling === 1
                ? 'iniciar o tratamento!'
                : 'finalizar o tratamento!'
                }.`,
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
      }
    },
    [cable.id, cable.handling, addToast, updateDashboard],
  );

  const handleFormSubmit = useCallback(
    async (data: INoteFormData) => {
      try {
        formNoteRef.current?.setErrors({});

        const schema = Yup.object().shape({
          text: Yup.string().required().max(1000, 'Máximo de 1000 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post('cables/note', { cable_id: cable.id, text: data.text })
          .then(() => {
            addToast({
              title: 'Observação adicionada com sucesso',
              type: 'default',
            });
            formNoteRef.current?.setData({ text: '' });

            updateDashboard();
          })
          .catch(err => {
            addToast({
              title: 'Falha ao salvar o comentário',
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          // console.log(errors);

          formNoteRef.current?.setErrors(errors);
        }
      }
    },
    [addToast, cable.id, updateDashboard],
  );

  const handleRequestStatus = useCallback(async () => {
    api.get<ICableRequestedData>(`cables/${cable.id}`).then(response => {
      const { status, last_status_date } = response.data;
      const auxCableRequestedStatus: ICableRequestedStatus = {} as ICableRequestedStatus;

      if (status !== 2) {
        auxCableRequestedStatus.status = 'online';
        auxCableRequestedStatus.date = `Atualizado: ${formatDate({
          date: new Date(),
          formatText: "dd/MM/yy 'às' HH:mm:ss",
        })}`;
      } else {
        auxCableRequestedStatus.status = 'sem sinal';
        auxCableRequestedStatus.date = last_status_date
          ? `Desde ${formatDate({
            date: new Date(last_status_date),
            formatText: "dd/MM/yy 'às' HH:mm:ss",
          })}`
          : 'Data não registrada';
      }

      setCableRequestedStatus(auxCableRequestedStatus);
    });
  }, [cable.id]);

  const level = cable.battery_level ? cable.battery_level * 10 + 10 : 100;
  // eslint-disable-next-line no-nested-ternary
  const signal = cable.status === 2 ? 0 : cable.signal_quality ? cable.signal_quality * 10 + 10 : 100;

  return (
    <Container>
      <Content>
        <Main>
          <div>
            <span>IM#{cable.identifier.substring(4)}</span>
            <h1>{cable.center}</h1>
            {cable.active && (cable.status === 1 || cable.status === 2) && (
              <span>{cable.lastStatusDate}</span>
            )}
          </div>
          <HorizontalLine>
            <div />
          </HorizontalLine>
          <Routes>
            {cable.status === 1 && (
              <>
                <RouteTitle type="alarmed">
                  Rotas rompidas{' '}
                  <small>({cable.alarmed_locations.length})</small>
                </RouteTitle>
                {cable.alarmed_locations.map((location, index) => {
                  return (
                    <>
                      <Route key={`${index + 0}`} type="alarmed">
                        <span>{location.from.address}</span>
                        {/* <Tooltip title={location.from.address} /> */}
                        <ArrowRightIcon />
                        <span>{location.to.address}</span>
                        {/* <Tooltip title={location.to.address} /> */}
                      </Route>
                      <HorizontalLineRoute
                        invisible={cable.alarmed_locations.length === index + 1}
                      >
                        <div />
                      </HorizontalLineRoute>
                    </>
                  );
                })}
              </>
            )}
            {((cable.status === 1 && cable.alarmed_locations.length < 4) ||
              cable.status !== 1) && (
                <>
                  <RouteTitle type="ok">
                    Rotas em funcionamento{' '}
                    <small>({cable.ok_locations.length})</small>
                  </RouteTitle>
                  {cable.ok_locations.map((location, index) => {
                    return (
                      <>
                        <Route key={`${index + 0}`} type="ok">
                          <span>{location.from.address}</span>
                          {/* limit was 35 */}
                          {/* <Tooltip title={location.from.address} /> */}
                          <ArrowRightIcon />
                          <span>{location.to.address}</span>
                          {/* <Tooltip title={location.to.address} /> */}
                        </Route>
                        <HorizontalLineRoute
                          invisible={cable.ok_locations.length === index + 1}
                        >
                          <div />
                        </HorizontalLineRoute>
                      </>
                    );
                  })}
                </>
              )}
          </Routes>
          {(cable.status === 1 || cable.handling === 2) && (
            <Handling>
              <HandlingStatus>
                {cable.handling === 1 && <h4>NÃO TRATADO</h4>}
                {cable.handling === 2 && <h4>EM TRATAMENTO</h4>}
                {cable.handling === 3 && <h4>TRATADO</h4>}
                {(cable.handling === 2 || cable.handling === 3) && (
                  <>
                    <div>
                      <UserLetters>
                        <p>
                          {user.avatar_url || getInitialNameLetters(user.name)}
                        </p>
                      </UserLetters>
                      <UserTooltip>
                        <div />
                        <UserLetters>
                          <p>
                            {user.avatar_url ||
                              getInitialNameLetters(user.name)}
                          </p>
                        </UserLetters>
                        <div>
                          <h3>{user.name}</h3>
                          <p>{user.email}</p>
                        </div>
                      </UserTooltip>
                    </div>
                    <div>
                      <p>
                        {cable.handling_date &&
                          formatDate({
                            date: new Date(cable.handling_date),
                            formatText: "dd/MM/yy 'às' HH:mm:ss",
                          })}
                      </p>
                    </div>
                  </>
                )}
                {cable.handling !== 3 && (
                  <button
                    type="button"
                    onClick={() => handlePopUpMessageHandling()}
                  >
                    {cable.handling === 1 ? (
                      <PlusIcon fill="#fff" />
                    ) : (
                      <CheckIcon />
                    )}
                    {cable.handling === 1 ? 'Iniciar tratamento' : 'Concluir'}
                  </button>
                )}
              </HandlingStatus>
              {(cable.handling === 2 || cable.handling === 3) && (
                <HandlingIdentifier>
                  Nº de ocorrência: <strong>{cable.handling_identifier}</strong>
                </HandlingIdentifier>
              )}
            </Handling>
          )}
          <Maintenance permission={editPermission}>
            <div>
              <p>
                {cable.under_maintenance ? 'EM MANUTENÇÃO' : 'SEM MANUTENÇÃO'}
              </p>
            </div>
            <button
              type="button"
              onClick={() => handlePopUpMessageUnderMaintenance()}
            >
              {' '}
              {cable.under_maintenance ? (
                <span>Finalizar manutenção</span>
              ) : (
                <>
                  <PlusIcon />
                  <span>Iniciar manutenção</span>
                </>
              )}
            </button>
          </Maintenance>
          <Notes>
            <h4>Observações</h4>
            <Form ref={formNoteRef} onSubmit={handleFormSubmit}>
              <UserLetters>
                <p>{user.avatar_url || getInitialNameLetters(user.name)}</p>
              </UserLetters>
              <Input name="text" type="text" label="Escreva um comentário" />
              <Button type="submit">Publicar</Button>
            </Form>
            {cable.notes.map(note => (
              <PreviousNote key={`${String(note.date_time)}-${note.name}`}>
                <UserLetters>
                  <p>{getInitialNameLetters(note.name)}</p>
                </UserLetters>
                <div>
                  <div>
                    <h5>{note.name}</h5>
                    <span>
                      {formatDate({
                        date: new Date(note.date_time),
                        formatText: "dd/MM/yy 'às' HH:mm:ss",
                      })}
                    </span>
                    <span>
                      {note.handling_identifier
                        ? `Observação durante tratativa de nº ${note.handling_identifier}`
                        : ''}
                    </span>
                  </div>
                  <textarea
                    name="note"
                    id=""
                    disabled
                    defaultValue={note.text}
                  />
                </div>
              </PreviousNote>
            ))}
          </Notes>
        </Main>
        <Side>
          <RiCloseFill onClick={() => closeCard()} />
          <div>
            <CardLog onClick={() => setShowLog(true)}>
              <HistoryIcon />
              <span>Histórico</span>
            </CardLog>
            <CardEditionOption
              onClick={() => {
                setShowCardEdition(editPermission);
              }}
              permission={editPermission}
            >
              <EditIcon />
              <span>Alterar dados</span>
            </CardEditionOption>
            <CardMap onClick={() => setShowMap(true)}>
              <MdMap />
              <span>Localidades</span>
            </CardMap>
            <div>
              {cable.door_opened ? (
                <>
                  <DoorOpenedIcon />
                  <span>Porta aberta</span>
                </>
              ) : (
                <>
                  <DoorClosedIcon />
                  <span>Porta fechada</span>
                </>
              )}
            </div>
            <div>
              {cable.on_battery ? (
                <>
                  {level < 33 && (
                    <TiBatteryLow
                      style={{
                        transform: 'rotate(-90deg)',
                        paddingBottom: '1px',
                      }}
                    />
                  )}
                  {level >= 33 && level < 66 && (
                    <TiBatteryMid
                      style={{
                        transform: 'rotate(-90deg)',
                        paddingBottom: '1px',
                      }}
                    />
                  )}
                  {level >= 66 && level < 100 && (
                    <TiBatteryHigh
                      style={{
                        transform: 'rotate(-90deg)',
                        paddingBottom: '1px',
                      }}
                    />
                  )}
                  {level >= 100 && (
                    <TiBatteryFull
                      style={{
                        transform: 'rotate(-90deg)',
                        paddingBottom: '1px',
                      }}
                    />
                  )}
                  <span>Na bateria: {level}%</span>
                </>
              ) : (
                <>
                  <PlugIcon />
                  <span>Na tomada</span>
                </>
              )}
            </div>
            <div>
              {signal <= 0 && <RiSignalWifiOffLine />}
              {signal > 0 && signal < 33 && <RiSignalWifi1Fill />}
              {signal >= 33 && signal < 66 && <RiSignalWifi2Fill />}
              {signal >= 66 && signal < 100 && <RiSignalWifi3Fill />}
              {signal >= 100 && <RiSignalWifiFill />}
              <span>Nível de sinal: {signal}%</span>
            </div>
          </div>
          <HorizontalLine>
            <div />
          </HorizontalLine>
          <State>
            <div>
              <h4>Atividade</h4>
              <StateToggle
                deactivate={!cable.active}
                onClick={() => handlePopUpMessageActivate()}
                permission={editPermission}
              >
                <div />
              </StateToggle>
            </div>
            <p>Ative ou desative essa Central</p>
          </State>
          {/* {cardUpdated && (
            <UpdateNote>
              <p>Atualizada</p>
              <span>A central sofreu atualizações</span>
            </UpdateNote>
          )} */}
          <RequestStatus>
            <Button type="submit" onClick={handleRequestStatus}>
              Resgatar o status
            </Button>
            {cableRequestedStatus && (
              <>
                <h4>
                  Status: <strong>{cableRequestedStatus.status}</strong>
                </h4>
                <small>{cableRequestedStatus.date}</small>
              </>
            )}
          </RequestStatus>
        </Side>
      </Content>
      {showCardEdition && (
        <CardEdition
          cable={cable}
          close={handleCloseCardEdition}
          // closeParentCard={handleCloseCardOpened}
          updateDashboard={updateDashboard}
        />
      )}
      {popUpMessageUnderMaintenance && (
        <PopUpMessage
          data={popUpMessageUnderMaintenance}
          action={handleUnderMaintenanceUpdate}
        />
      )}

      {popUpMessageActivate && (
        <PopUpMessage data={popUpMessageActivate} action={handleActiveUpdate} />
      )}

      {popUpMessageHandling && (
        <PopUpMessage
          data={popUpMessageHandling}
          action={handleHandlingUpdate}
        />
      )}

      {showLog && (
        <Log
          cablesList={cablesList}
          currentCable={cable}
          close={handleCloseCardLog}
        />
      )}
      {showMap && (
        <Map
          close={handleCloseMap}
          markersList={markersList}
          initialSelectedMarker={markersList.find(
            marker => marker.id === cable.id,
          )}
        />
      )}
    </Container>
  );
};

export default CardOpened;
