import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { MdHistory, MdMap } from 'react-icons/md';

import ICableDataDTO2 from '../../dtos/ICableDataDTO2';
import IPopUpMessageDTO from '../../dtos/IPopUpMessageDTO';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import formatDate from '../../utils/formatDate';
import getInitialNameLetters from '../../utils/getInitialNameLetters';

import Card from '../../components/Card';
import CardOpened from '../../components/CardOpened';
import PopUpMessage from '../../components/PopUpMessage';
import Tooltip from '../../components/Tooltip';
import DropdownMenu from '../../components/DropdownMenu';
import Log from '../../components/Log';
// import Profile from '../../components/Profile';

import {
  Container,
  Header,
  HeaderContent,
  Logo,
  Navigation,
  VerticalLine,
  ProfileMenu,
  UserAvatar,
  Content,
  CardsPanel,
  CardPanelButton,
  CardsDashboard,
  Alarms,
  NoCardMessage,
} from './styles';

import { ReactComponent as HelpCircleIcon } from '../../assets/help-circle.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle.svg';
import { ReactComponent as XCircleIcon } from '../../assets/x-circle.svg';
import { ReactComponent as MinusCircleIcon } from '../../assets/minus-circle.svg';
import logoImg from '../../assets/logo_dashboard.png';
import api from '../../services/api';
import Map from '../../components/MapContainer';
import IMarkerDTO from '../../dtos/IMarkerDTO';

interface CablesType {
  type: 'alarmed' | 'ok' | 'caution' | 'disabled';
}

interface ICableMainData {
  id: string;
  center: string;
}

const Dashboard: React.FC = () => {
  const { signOut, user } = useAuth();
  const { addToast } = useToast();
  const timeNow = useRef(new Date());

  const [cablesData, setCablesData] = useState<ICableDataDTO2[]>([]);
  const [showCablesType, setShowCablesType] = useState<CablesType>({
    type: 'alarmed',
  });
  const [currentOpenedCard, setcurrentOpenedCard] =
    useState<ICableDataDTO2 | null>(null);
  const [popUpMessageActivate, setPopUpMessageActivate] =
    useState<IPopUpMessageDTO | null>(null);
  const [currentPopUpCard, setCurrentPopUpCard] =
    useState<ICableDataDTO2 | null>(null);
  const [profileDropedDown, setProfileDropedDown] = useState(false);
  const [showLog, setShowLog] = useState(false);

  const [showMap, setShowMap] = useState(false);

  // const [showProfile, setShowProfile] = useState(false);
  // const [openedCardUpdated, setOpenedCardUpdated] = useState(false);
  // const [helpDropedDown, setHelpDropedDown] = useState(false);

  // const [favoriteFilter, setFavoriteFilter] = useState(false);
  // const [affiliatedFilter, setAffiliatedFilter] = useState(false);
  //
  const handleOpenCard = useCallback((cable: ICableDataDTO2) => {
    setcurrentOpenedCard(cable);
  }, []);

  const handleCloseCard = useCallback(() => {
    setcurrentOpenedCard(null);
  }, []);

  // const handleCloseProfile = useCallback(() => {
  //   setShowProfile(false);
  // }, []);

  const handleCloseCardLog = useCallback(() => {
    setShowLog(false);
  }, [setShowLog]);

  const handleCloseMap = useCallback(() => {
    setShowMap(false);
  }, []);

  const handleCablesData = useCallback(
    async (forceUpdate: boolean) => {
      api
        .get<ICableDataDTO2[] | boolean>(`/cables?forceUpdate=${forceUpdate}`)
        .then(response => {
          if (response.data !== true && response.data !== false) {
            setCablesData(
              response.data.map(cable => {
                const {
                  lastStatusDate,
                  alarmed_locations,
                  center,
                  under_maintenance,
                } = cable;
                let lastDate: Date | undefined;

                if (alarmed_locations.length) {
                  if (alarmed_locations[0].alarmedDate) {
                    lastDate = new Date(alarmed_locations[0].alarmedDate);

                    if (!under_maintenance && lastDate > timeNow.current) {
                      addToast({
                        title: `Atenção! ${center} está com ${alarmed_locations.length} rota(s) rompida(s)`,
                        description: formatDate({
                          date: lastDate,
                          formatText: "dd/MM/yy 'às' HH:mm:ss",
                        }),
                        type: 'alarmed',
                      });
                    }
                  }
                } else if (lastStatusDate) {
                  lastDate = new Date(lastStatusDate);
                }

                const cableUpdated = {
                  ...cable,
                  lastStatusDate: lastDate
                    ? formatDate({
                      date: lastDate,
                      formatText: "'Desde' dd/MM/yy 'às' HH:mm:ss",
                    })
                    : 'Data não registrada',
                };

                return cableUpdated;
              }),
            );
            timeNow.current = new Date();
          }
        });
    },
    [addToast],
  );

  const handleOpenPopUpActivate = useCallback(
    (cable: ICableDataDTO2) => {
      if (!cable.active) {
        setPopUpMessageActivate({
          title: `Confirma que a unidade ${cable.center} será reativada?`,
          description:
            'As notificações, registros de log e mudança de status voltarão a ocorrer.',
        });
        setCurrentPopUpCard(cable);
      }
    },
    [setPopUpMessageActivate, setCurrentPopUpCard],
  );

  const handleActiveUpdate = useCallback(
    (confirmation: boolean) => {
      setPopUpMessageActivate(null);

      if (confirmation && currentPopUpCard) {
        api
          .patch('/cables/activate', {
            cable_id: currentPopUpCard.id,
            activate: !currentPopUpCard.active,
          })
          .then(() => {
            let tab: string;

            switch (currentPopUpCard.status) {
              case 0:
                tab = 'funcionando';
                break;
              case 1:
                tab = 'alarmado';
                break;
              default:
                tab = 'sem contato';
                break;
            }

            addToast({
              title: `Unidade ativada! Movida para a aba ${tab}`,
              type: 'default', // Talvez salvar
            });
            handleCablesData(true);
            setCurrentPopUpCard(null);
          })
          .catch(err => {
            addToast({
              title: `Falha ao ativar a unidade.`,
              description: `Erro: ${err.response.data.message}`,
              type: 'error',
            });
          });
      }
    },
    [currentPopUpCard, handleCablesData, addToast],
  );

  useEffect(() => {
    handleCablesData(true);

    const interval = setInterval(() => {
      handleCablesData(false);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [handleCablesData]);

  useEffect(() => {
    // Sempre que atualizar o servidor, atualizar o card aberto
    if (currentOpenedCard) {
      const cableUpdated = cablesData.find(
        cable => cable.id === currentOpenedCard.id,
      );
      setcurrentOpenedCard(cableUpdated || null);
      if (cableUpdated) {
        handleOpenCard(cableUpdated);
      }
    }
  }, [cablesData, currentOpenedCard, handleOpenCard]);

  const alarmedCables = useMemo(() => {
    return cablesData.filter(
      cable => cable.active && (cable.status === 1 || cable.handling === 2),
    );
  }, [cablesData]);

  const okCables = useMemo(() => {
    return cablesData.filter(cable => cable.active && cable.status === 0);
  }, [cablesData]);

  const cautionCables = useMemo(() => {
    return cablesData.filter(cable => cable.active && cable.status === 2);
  }, [cablesData]);

  const disabledCables = useMemo(() => {
    return cablesData.filter(cable => !cable.active);
  }, [cablesData]);

  // Entregue à listagem de log
  const cablesList: ICableMainData[] = useMemo(() => {
    return cablesData.map(cable => {
      const { id, center } = cable;
      return { id, center };
    });
  }, [cablesData]);

  const markersList: IMarkerDTO[] = useMemo(() => {
    return cablesData
      .filter(cable => cable.lat && cable.lng)
      .map(marker => {
        const { id, center, lat, lng, status } = marker;

        return { id, center, lat, lng, status };
      });
  }, [cablesData]);

  return (
    <Container>
      <CardsPanel>
        <Logo src={logoImg} alt="Logo" />

        <CardPanelButton
          type="button"
          onClick={() => setShowCablesType({ type: 'alarmed' })}
          active={showCablesType.type === 'alarmed'}
        >
          <XCircleIcon />
          <div>
            <small>{alarmedCables.length}</small>
          </div>
          <Tooltip title="Alarmado" />
        </CardPanelButton>
        <CardPanelButton
          type="button"
          onClick={() => setShowCablesType({ type: 'caution' })}
          active={showCablesType.type === 'caution'}
        >
          <HelpCircleIcon />
          <div>
            <small>{cautionCables.length}</small>
          </div>

          <Tooltip title="Sem comunicação" />
        </CardPanelButton>
        <CardPanelButton
          type="button"
          onClick={() => setShowCablesType({ type: 'ok' })}
          active={showCablesType.type === 'ok'}
        >
          <CheckCircleIcon />
          <div>
            <small>{okCables.length}</small>
          </div>

          <Tooltip title="Em funcionamento" />
        </CardPanelButton>
        <CardPanelButton
          type="button"
          onClick={() => setShowCablesType({ type: 'disabled' })}
          active={showCablesType.type === 'disabled'}
        >
          <MinusCircleIcon />
          <div>
            <small>{disabledCables.length}</small>
          </div>

          <Tooltip title="Desativado" />
        </CardPanelButton>
      </CardsPanel>

      <Content>
        <Header>
          <HeaderContent>
            <Navigation>
              <div>
                <div>
                  <MdMap onClick={() => setShowMap(true)} />
                </div>
                <div>
                  <MdHistory onClick={() => setShowLog(true)} />
                </div>
              </div>
              {/* <div
                onMouseEnter={() => setHelpDropedDown(true)}
                onMouseLeave={() => setHelpDropedDown(false)}
              >
                <div>
                  <MdClose />
                  {helpDropedDown && (
                    <DropdownMenu
                      options={[
                        { title: 'Perguntas frequentes', link: '/' },
                        { title: 'F.A.Q.', link: '/' },
                        { title: 'Fale conosco', link: '/' },
                      ]}
                    />
                  )}
                </div>
              </div> */}
            </Navigation>
            <VerticalLine>
              <div />
            </VerticalLine>
            <ProfileMenu
              onMouseEnter={() => setProfileDropedDown(true)}
              onMouseLeave={() => setProfileDropedDown(false)}
            >
              <div>
                <h5>{user.name}</h5>
                <div>
                  <FiChevronDown />
                  {/* <span>Gerente</span> */}
                </div>
              </div>
              <UserAvatar>
                <h3>{user.avatar_url || getInitialNameLetters(user.name)}</h3>
              </UserAvatar>
              {profileDropedDown && (
                <DropdownMenu
                  options={[
                    // { title: 'Perfil', action: () => setShowProfile(true) },
                    {
                      title: 'Sair',
                      action: () => signOut(),
                    },
                  ]}
                />
              )}
            </ProfileMenu>
          </HeaderContent>
          {/* <Filter>
            <FilterOption
              onClick={() => setFavoriteFilter(!favoriteFilter)}
              active={favoriteFilter}
            >
              Favoritos
            </FilterOption>
            <FilterOption
              onClick={() => setAffiliatedFilter(!affiliatedFilter)}
              active={affiliatedFilter}
            >
              Filiais
            </FilterOption>
          </Filter> */}
        </Header>
        <CardsDashboard>
          <Alarms>
            {showCablesType.type === 'alarmed' &&
              (alarmedCables.length ? (
                alarmedCables.map(cable => {
                  return (
                    <Card
                      key={cable.id}
                      cable={cable}
                      openCard={handleOpenCard}
                    />
                  );
                })
              ) : (
                <NoCardMessage>
                  <h2>
                    Tudo certo por aqui! Nenhuma central encontra-se rompida
                  </h2>
                </NoCardMessage>
              ))}
            {showCablesType.type === 'caution' &&
              (cautionCables.length ? (
                cautionCables.map(cable => {
                  return (
                    <Card
                      key={cable.id}
                      cable={cable}
                      openCard={handleOpenCard}
                    />
                  );
                })
              ) : (
                <NoCardMessage>
                  <h2>
                    Tudo certo por aqui! Todas as centrais estão se comunicando
                  </h2>
                </NoCardMessage>
              ))}
            {showCablesType.type === 'ok' &&
              (okCables.length ? (
                okCables.map(cable => {
                  return (
                    <Card
                      key={cable.id}
                      cable={cable}
                      openCard={handleOpenCard}
                    />
                  );
                })
              ) : (
                <NoCardMessage>
                  <h2>
                    As centrais podem estar sem comunicação, rompidas ou
                    desativadas
                  </h2>
                </NoCardMessage>
              ))}
            {showCablesType.type === 'disabled' &&
              (disabledCables.length ? (
                disabledCables.map(cable => {
                  return (
                    <Card
                      key={cable.id}
                      cable={cable}
                      openPopUpActivate={handleOpenPopUpActivate}
                      popUpActivateOpened={
                        currentPopUpCard && currentPopUpCard.id === cable.id
                          ? !!popUpMessageActivate
                          : false
                      }
                    />
                  );
                })
              ) : (
                <NoCardMessage>
                  <h2>Tudo certo por aqui! Todas as centrais estão ativas</h2>
                </NoCardMessage>
              ))}
          </Alarms>
        </CardsDashboard>
      </Content>
      {currentOpenedCard && (
        <CardOpened
          cable={currentOpenedCard}
          closeCard={handleCloseCard}
          updateDashboard={() => {
            handleCablesData(true);
          }}
          editPermission={user.user_type === 2 || user.editing_privilege}
          cablesList={cablesList}
          markersList={markersList}
        // cardUpdated={openedCardUpdated}
        />
      )}
      {popUpMessageActivate && (
        <PopUpMessage data={popUpMessageActivate} action={handleActiveUpdate} />
      )}

      {showLog && <Log cablesList={cablesList} close={handleCloseCardLog} />}

      {/* {showProfile && <Profile close={handleCloseProfile} />} */}

      {showMap && <Map close={handleCloseMap} markersList={markersList} />}
    </Container>
  );
};

export default Dashboard;
