import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

// Por enquanto, não tem influencia
interface TypeProps {
  type: 'alarmed' | 'success' | 'error' | 'default';
}

const types = {
  alarmed: css`
    background: #fff;
  `,
  success: css`
    background: var(--success2);
  `,
  error: css`
    background: var(--problem2);
  `,
  default: css`
    background: var(--action2);
  `,
};

export const Container = styled(animated.div)<TypeProps>`
  display: flex;
  justify-content: center;
  box-shadow: var(--shadow-large);
  margin-bottom: var(--space30);
  position: relative;

  ${props =>
    props.type === 'alarmed'
      ? css`
          background: var(--problem2);
          color: #fff;
        `
      : css`
          background: #fff;
          span {
            color: var(--support-text);
          }
        `}
`;

export const VerticalLine = styled.div<TypeProps>`
  padding: var(--space15);
  padding-right: var(--space30);

  div {
    ${props => types[props.type]};
    width: 4px;
    height: 100%;
  }
`;

export const IconContainer = styled.div<TypeProps>`
  border-radius: 50%;
  margin: auto 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.type === 'alarmed'
      ? css`
          color: var(--problem2);
        `
      : css`
          color: #fff;
        `}
  ${props => types[props.type]};
`;

export const Text = styled.div`
  margin: var(--space30);
  width: 460px;

  p {
    font-size: 18px;
  }

  span {
    font-size: 14px;
  }
`;

export const CloseButton = styled.div`
  color: var(--support-text);
  margin: auto 0;
  margin-right: var(--space30);
  cursor: pointer;
`;
