import styled from 'styled-components';

export const Container = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const Content = styled.div`
  width: 860px;
  box-shadow: var(--shadow-large);
`;

export const Header = styled.div`
  background: #fff;
  padding: var(--space30);

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    select {
      padding: 12px 15px;
      font-family: 'DM Sans', sans-serif;
      border: 1px solid var(--bg2);
      flex: 0.5;

      option {
        padding: 12px 15px;
      }
    }

    > svg {
      color: #b2b2b2;
      width: 25px;
      height: 25px;
      margin-left: auto;
      display: block;
      cursor: pointer;
    }
  }
`;

export const MapArea = styled.div`
  height: 80vh;
`;
