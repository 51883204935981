import React from 'react';
import {
  TiBatteryFull,
  TiBatteryHigh,
  TiBatteryMid,
  TiBatteryLow,
} from 'react-icons/ti';
import {
  RiSignalWifiFill,
  RiSignalWifi1Fill,
  RiSignalWifi2Fill,
  RiSignalWifi3Fill,
  RiSignalWifiOffLine,
} from 'react-icons/ri';

import ICableDataDTO2 from '../../dtos/ICableDataDTO2';
import Tooltip from '../Tooltip';
import limitText from '../../utils/limitText';

import {
  Container,
  Info,
  Content,
  Bolao,
  Tags,
  State,
  StateToggle,
} from './styles';

import { ReactComponent as PlugIcon } from '../../assets/plug.svg';
import { ReactComponent as DoorOpenedIcon } from '../../assets/door-opened.svg';
import { ReactComponent as DoorClosedIcon } from '../../assets/door-closed.svg';

interface CardProps {
  cable: ICableDataDTO2;
  openCard?(cable: ICableDataDTO2): void;
  openPopUpActivate?(cable: ICableDataDTO2): void;
  popUpActivateOpened?: boolean;
}

const Card: React.FC<CardProps> = ({
  cable,
  openCard,
  openPopUpActivate,
  popUpActivateOpened = false,
}) => {
  const level = cable.battery_level ? cable.battery_level * 10 + 10 : 100;
  // eslint-disable-next-line no-nested-ternary
  const signal = cable.status === 2 ? 0 : cable.signal_quality ? cable.signal_quality * 10 + 10 : 100;

  const totalAlarmedLocations = cable.alarmed_locations.length;
  return (
    <Container
      active={cable.active}
      onClick={() => {
        if (openCard) {
          openCard(cable);
        }
      }}
    >
      <Info>
        {/* <div>
          <HistoryIcon />
          <Tooltip title="Histórico" />
        </div> */}
        <div>
          {cable.on_battery ? (
            <>
              {level < 33 && (
                <TiBatteryLow
                  style={{
                    transform: 'rotate(-90deg)',
                    paddingBottom: '1px',
                  }}
                />
              )}
              {level >= 33 && level < 66 && (
                <TiBatteryMid
                  style={{
                    transform: 'rotate(-90deg)',
                    paddingBottom: '1px',
                  }}
                />
              )}
              {level >= 66 && level < 100 && (
                <TiBatteryHigh
                  style={{
                    transform: 'rotate(-90deg)',
                    paddingBottom: '1px',
                  }}
                />
              )}
              {level >= 100 && (
                <TiBatteryFull
                  style={{
                    transform: 'rotate(-90deg)',
                    paddingBottom: '1px',
                  }}
                />
              )}
              <Tooltip title={`Bateria: ${level}%`} />
            </>
          ) : (
            <>
              <PlugIcon />
              <Tooltip title="Na tomada" />
            </>
          )}
        </div>
        <div>
          {cable.door_opened ? (
            <>
              <DoorOpenedIcon />
              <Tooltip title="Porta aberta" />
            </>
          ) : (
            <>
              <DoorClosedIcon />
              <Tooltip title="Porta fechada" />
            </>
          )}
        </div>
        <div>
          {signal <= 0 && <RiSignalWifiOffLine />}
          {signal > 0 && signal < 33 && <RiSignalWifi1Fill />}
          {signal >= 33 && signal < 66 && <RiSignalWifi2Fill />}
          {signal >= 66 && signal < 100 && <RiSignalWifi3Fill />}
          {signal >= 100 && <RiSignalWifiFill />}
          <Tooltip title={`Sinal: ${signal}%`} />
        </div>
      </Info>
      <Content>
        <span>IM#{cable.identifier.substring(4)}</span>
        <Bolao statusColor={cable.active ? cable.status : 3} />
        <h3>{limitText({ text: cable.center, limit: 55 })}</h3>
        {cable.active && cable.status === 1 && (
          <p>
            {totalAlarmedLocations}
            {totalAlarmedLocations > 1 ? ` rotas rompidas` : ` rota rompida`}
          </p>
        )}
        {cable.active && (cable.status === 1 || cable.status === 2) && (
          <span>{cable.lastStatusDate}</span>
        )}
        <Tags>
          {cable.handling === 1 && <p>NÃO TRATADO</p>}
          {cable.handling === 2 && <p>EM TRATAMENTO</p>}
          {cable.handling === 3 && <p>TRATADO</p>}
          {cable.active && cable.under_maintenance && <p>EM MANUTENÇÃO</p>}
        </Tags>

        {!cable.active && (
          <State>
            <div>
              <p>Atividade</p>
              <StateToggle
                deactivate={!popUpActivateOpened}
                onClick={() => {
                  if (openPopUpActivate) {
                    openPopUpActivate(cable);
                  }
                }}
              >
                <div />
              </StateToggle>
            </div>
          </State>
        )}
      </Content>
    </Container>
  );
};

export default Card;
