import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from 'react';
import GoogleMap from 'google-map-react';
import { RiCloseFill } from 'react-icons/ri';

import { Container, Content, Header, MapArea } from './styles';
import IMarkerDTO from '../../dtos/IMarkerDTO';
import Marker from './Marker';

interface ICoords {
  lat: number;
  lng: number;
}

interface MapProps {
  close(): void;
  markersList: IMarkerDTO[];
  initialSelectedMarker?: IMarkerDTO;
}

const MapContainer: React.FC<MapProps> = ({
  close,
  markersList,
  initialSelectedMarker,
}) => {
  const defaultCenter = useMemo(
    () => ({
      lat: -22.84,
      lng: -43.24,
    }),
    [],
  );
  const defaultZoom = 5;

  const [center, setCenter] = useState<ICoords>(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const [markers, setMarkers] = useState<IMarkerDTO[]>([]);

  const [selectedMarker, setSelectedMarker] = useState<IMarkerDTO>();

  const selectRef = useRef<HTMLSelectElement>(null);
  // const mapRef = useRef<MapOptions>(null);

  const handleZoom = useCallback((m: IMarkerDTO) => {
    const { lat, lng } = m;

    setInterval(() => {
      setZoom(13);
    }, 0);

    setCenter({ lat, lng });
    setZoom(12);
    setSelectedMarker(m);

    if (selectRef.current?.value) {
      selectRef.current.value = m.id;
    }
  }, []);

  useEffect(() => {
    if (initialSelectedMarker) {
      const { lat, lng } = initialSelectedMarker;
      setCenter({ lat, lng });
      setZoom(12);
      setSelectedMarker(initialSelectedMarker);
    }
  }, [initialSelectedMarker]);

  useEffect(() => {
    setMarkers(markersList);

    // const bounds = new window.google.maps.LatLngBounds();

    // markers.map(item => {
    //   bounds.extend({ lat: item.lat, lng: item.lng });
    //   return item.id;
    // });

    // GoogleMap.fitBounds(
    //   {
    //     ne: { lat: -22, lng: 88 },
    //     sw: { lat: -42, lng: 108 },
    //   },
    //   { height: 300, width: 300 },
    // );
    // const bounds: NESWBounds = {
    //   ne: { lat: -22, lng: 88 },
    //   sw: { lat: -42, lng: 108 },
    // };

    // fitBounds(bounds, { height: 300, width: 300 });
  }, [markers, markersList]);

  const handleSelectMarker = useCallback(() => {
    const selectedMarkerId = selectRef.current?.value;

    if (selectedMarkerId === '0') {
      setCenter(defaultCenter);
      setZoom(defaultZoom);
    }

    const marker = markers.find(m => m.id === selectedMarkerId);

    if (marker) {
      const { lat, lng } = marker;
      setCenter({ lat, lng });
      setZoom(13);
      setSelectedMarker(marker);
    }
  }, [markers, defaultCenter, defaultZoom]);

  return (
    <Container>
      <Content>
        <Header>
          <div>
            <select
              ref={selectRef}
              name="centers"
              onChange={handleSelectMarker}
              defaultValue={
                initialSelectedMarker ? initialSelectedMarker.id : '0'
              }
            >
              <option value="0">Todos</option>
              {markers.map(marker => (
                <option value={marker.id}>{marker.center}</option>
              ))}
            </select>
            <RiCloseFill onClick={close} />
          </div>
        </Header>
        <MapArea>
          <GoogleMap
            // ref={mapRef}
            zoom={zoom}
            center={center}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{
              key: 'AIzaSyDgdP9hr2S_ML0pKUJ9A6E9jB2W3EDKxk4',
            }}
            resetBoundsOnResize
          >
            {markers.map(marker => (
              <Marker
                lat={marker.lat}
                lng={marker.lng}
                marker={marker}
                label={marker.center}
                status={marker.status}
                zoom={m => handleZoom(m)}
                selected={marker.id === selectedMarker?.id}
              />
            ))}
          </GoogleMap>
        </MapArea>
      </Content>
    </Container>
  );
};

export default MapContainer;
