import styled, { css } from 'styled-components';

interface CardPanelButtonProps {
  active: boolean;
}

interface FilterOptionProps {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  /* position: relative; */
`;

export const CardsPanel = styled.div`
  min-height: 100vh;
  background: var(--support1);
  width: 6rem;
  z-index: 1;
`;

export const Logo = styled.img`
  display: block;
  margin: var(--space30) auto;
  height: 3rem;
`;

export const CardPanelButton = styled.button<CardPanelButtonProps>`
  background: transparent;
  width: 100%;
  height: 5rem;
  padding: var(--space15);
  border: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: relative;
  transition-duration: 0.5s;

  &:hover > svg,
  &:hover > div:nth-child(2) small {
    transition-duration: 0.5s;
    fill: var(--bg2);
    color: var(--bg2);
  }

  svg {
    margin: 0 auto;
    fill: var(--support_contrast1);
    height: 36px;
    width: 36px;
  }

  &:hover > div:nth-child(3) {
    transition-duration: 0.5s;
    display: block;
    top: -17px;
    right: -75px;

    span {
      font-size: 14px;
    }
  }

  > div:nth-child(2) {
    width: 25%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    small {
      color: var(--support_contrast2);
      font-size: 14px;
      font-weight: bold;
    }
  }

  ${props =>
    props.active &&
    css`
      border-left: 5px solid var(--bg2);
      > svg {
        fill: var(--bg2);
      }
      > div:nth-child(2) small {
        color: var(--bg2);
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  background: #fff;
  height: fit-content;
  box-shadow: var(--shadow-short);
`;

export const HeaderContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 var(--space30);
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: var(--space15) 0;

    margin-right: var(--space15);

    > div {
      padding: 5px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid var(--bg2);
      margin-left: var(--space10);
      cursor: pointer;

      &:hover {
        background: var(--bg2);
        transition-duration: 0.2s;
      }

      div {
        top: 4rem;
        right: var(--space15);
      }

      svg {
        display: block;
        height: 24px;
        width: 24px;
      }
    }
  }
`;

export const VerticalLine = styled.div`
  padding: var(--space15) 0;
  height: 100%;

  div {
    width: 1px;
    height: 100%;

    background: var(--bg2);
  }
`;

export const ProfileMenu = styled.div`
  display: flex;
  align-items: center;
  padding: var(--space15) 0;
  padding-left: var(--space15);
  position: relative;
  height: 100%;
  cursor: pointer;
  z-index: 1;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: var(--space10);

    h5 {
      color: var(--action2);
    }

    div {
      margin-left: auto;
      display: flex;
      align-items: center;
      color: #b7b7b7;

      span {
        color: var(--support-text);
      }
    }
  }
  > div:last-child {
    top: 4rem;
    right: 0;
  }
`;

export const UserAvatar = styled.div`
  font-size: 24px;
  color: #fff;
  height: 45px;
  width: 45px;
  background: var(--action2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Filter = styled.div`
  flex: 1;
  background: #fff;
  border-top: 1px solid var(--bg2);
  display: flex;

  padding: var(--space10);
  padding-left: var(--space30);
`;

export const FilterOption = styled.div<FilterOptionProps>`
  font-size: 12px;
  text-align: center;
  color: var(--support-text);
  background: #fff;
  padding: 3px var(--space10);
  border: 1px solid var(--bg2);
  cursor: pointer;

  transition: 0.2s ease all;

  & + div {
    margin-left: var(--space10);
  }

  ${props =>
    props.active
      ? css`
          color: var(--action2);
          background: var(--action_bg);
          border: 1px solid var(--action2);
          border-radius: 50px;
        `
      : css`
          &:hover {
            border: 1px solid var(--bg3);
          }
        `}
`;

export const CardsDashboard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Alarms = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  /* grid-template-rows: auto; */
  /* grid-auto-flow: row; */
  column-gap: var(--space30);
  row-gap: var(--space30);
  margin: var(--space30);
  /* grid-template-rows: 200px 200px 200px 200px; */
`;

export const NoCardMessage = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);

  h2 {
    color: var(--support-text);
  }
`;
