import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;

// Aqui reune-se todos os hooks criados. Despoluindo o App.tsx
// Tanto faz qual provider ficará dentro do outro, nesse caso acima,
// um não depende do outro.
