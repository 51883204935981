import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;

  input {
    font-size: 14px;
    color: #707070;
    background: #fff;
    padding: 12px 15px;
    padding-right: 40px;
    border: 1px solid var(--bg2);
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease all;

    &:hover {
      border: 1px solid var(--bg3);
    }
  }

  > span {
    color: var(--support-text);
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 12px;
    transition: 0.2s ease all;
  }

  ${props =>
    props.isFocused &&
    css`
      input,
      input:hover {
        border: 1px solid var(--action2);
        transition: 0.2s ease all;

        & + span {
          top: -7px;
          left: 11px;
          font-size: 11px;
          color: #b2b2b2;
          background: #fff;
          padding: 0 5px;
          height: fit-content;
          color: var(--action2);
          transition: 0.2s ease all;
        }
      }

      svg {
        fill: var(--support-text);
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      input + span {
        top: -7px;
        left: 11px;
        font-size: 11px;
        background: #fff;
        padding: 0 5px;
        height: fit-content;
      }
    `}

  ${props =>
    props.isErrored &&
    css`
      input,
      input:hover {
        border: 1px solid var(--problem2);
        transition: 0.2s ease all;

        & + span {
          color: var(--problem2);
        }
      }

      svg {
        fill: var(--problem2);

        &:hover + div {
          display: block;
          top: 50%;
          position: absolute;
          right: 15px;
        }
      }
    `}

  svg {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 15px;
    right: 15px;
    /* cursor: pointer; */
  }
`;

export const Error = styled(Tooltip)`
  /* display: block;
  top: 50%;
  position: absolute;
  right: 17%; */
`;
