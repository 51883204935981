import React, { useRef } from 'react';
import ReactSelect, { OptionTypeBase, Props } from 'react-select';
import { useField } from '@unform/core';

import { useEffect } from 'react';

interface IOption {
  label: string;
  value: string;
  hidden?: boolean;
}

interface SelectProps extends Props<OptionTypeBase> {
  name: string;
  options?: IOption[];
  defaultOption?: IOption;
}

const Select: React.FC<SelectProps> = ({
  name,
  defaultOption,
  options,
  styles,
  ...rest
}) => {
  const { fieldName, registerField } = useField(name);
  const selectRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ReactSelect
      isSearchable={false}
      defaultValue={defaultOption}
      ref={selectRef}
      classNamePrefix="react-select"
      options={options}
      {...rest}
      styles={{
        ...styles,
        option: (provided, state) => ({
          ...provided,
          background: '#fff',
          color: state.isSelected ? 'var(--action2);' : 'var(--support1);',
          ':hover': {
            background: 'var(--bg1)',
          },
        }),
        container: provided => ({
          ...provided,
          background: '#fff',
          flex: 1,
          // display: 'flex',
          // alignItems: 'center',
        }),
        control: provided => ({
          ...provided,
          // background: '#fff',
          border: '1px solid var(--bg2)',
          borderRadius: 0,
          width: '100%',
        }),
        dropdownIndicator: () => ({
          // ...provided,
          marginTop: '5px',

          // color: 'tomato',
        }),
        indicatorsContainer: () => ({
          // ...provided,
          // margin: '0px',
          // padding: '0px',
          // background: 'green',
          // height: 0,
        }),
        valueContainer: () => ({
          padding: '0 10px',
          fontSize: '14px',
          // ...provided,
          // paddingLeft: '10px',
          // height: 0,
        }),
        singleValue: provided => ({
          ...provided,
          // padding: '0 10px',
          width: '80%',
        }),
      }}
    />
  );
};

export default Select;
