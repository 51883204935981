import React, { createContext, useCallback, useContext, useState } from 'react';
import { uuid } from 'uuidv4';
import Sound from 'react-sound';
import ToastContainer from '../components/ToastContainer'; // Tudo referente ao toast, faz sentido estar aqui, no hook dele
import alertSound from '../assets/alert.mp3';

export interface ToastMessage {
  id: string;
  type: 'alarmed' | 'success' | 'error' | 'default';
  title: string;
  description?: string;
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);
  const [existsAlarmedMessage, setExistsAlarmedMessage] = useState(false);

  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const toast = {
        id: uuid(),
        type,
        title,
        description,
      };

      if (type === 'alarmed') {
        setExistsAlarmedMessage(true);
      }

      setMessages(state => [...state, toast]);
    },
    [],
  );

  const removeToast = useCallback(
    (id: string) => {
      setMessages(state => state.filter(message => message.id !== id));

      if (messages.filter(message => message.type !== 'alarmed')) {
        setExistsAlarmedMessage(false);
      }
    },
    [messages],
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {existsAlarmedMessage && (
        <Sound url={alertSound} playStatus="PLAYING" loop />
      )}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
