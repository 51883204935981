import styled from 'styled-components';

export const Container = styled.div`
  background: transparent;
  /* width: 640px; */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  /* overflow: hidden; */
  z-index: 3;
`;
