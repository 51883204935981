import styled from 'styled-components';

export const Container = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background: #fff;
  width: 640px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  /* padding: var(--space30); */
`;

export const Disclaimer = styled.div`
  padding: var(--space30);
  div {
    display: flex;
    align-items: center;

    svg {
      color: #b2b2b2;
      width: 25px;
      height: 25px;
      margin-left: auto;
      display: block;
      cursor: pointer;
    }
  }
  p {
    color: var(--support-text);
    font-weight: 500;
    margin-top: 5px;
  }
`;

export const Filter = styled.div`
  background: var(--bg1);
  padding: var(--space15) var(--space30);
  /* margin: var(--space30) 0; */
  position: relative;
  form {
    display: flex;
    align-items: center;
    column-gap: 16px;
    > div {
      flex: 1;
      /* display: flex;
      align-items: center; */

      p {
        color: var(--support1);
        /* margin-right: var(--space15); */
        margin-bottom: 4px;
      }
    }

    > div:nth-child(2) {
      /* flex: 0.7;
      margin-left: var(--space15); */
    }

    > button {
      /* flex: 0.3; */
      width: fit-content;
      padding: 10px 16px;
      margin-top: 20px;
      /* margin-left: var(--space15); */
    }

    > div:last-child {
      flex: 0.1;
      /* position: absolute;
      right: var(--space30);
      top: 8px; */
      margin-top: auto;
      svg {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
`;

export const LogList = styled.div`
  overflow-y: scroll;
  padding: var(--space30);
  flex-grow: 1;
  /* max-height: fit-content; */
  hr {
    background: var(--bg1);
    height: 1px;
    border: 0;
    margin: var(--space30) 0;
  }
`;

export const LogRegister = styled.div`
  margin: var(--space15) 0;
  display: flex;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const UserAvatar = styled.div`
  font-size: 11px;
  color: #fff;
  height: 24px;
  width: 24px;
  background: var(--action2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogDescription = styled.div`
  padding-left: var(--space15);
  flex: 1;
  small {
    font-size: 11px;
    color: var(--support-text);
    display: block;
  }
  span {
    font-size: 1rem;
  }
`;

export const NoLogMessage = styled.div`
  position: absolute;
  left: 50%;
  /* top: 50%; */
  transform: translate(-50%);

  h4 {
    color: var(--support-text);
  }
`;
