import styled from 'styled-components';

export const Container = styled.div`
  background: #00000050;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    background: #fff;
    width: 420px;
    padding: var(--space30);
    box-shadow: var(--shadow-large);

    text-align: center;

    p {
      font-size: 14px;
      color: var(--support-text);
      margin-top: var(--space10);
    }

    div {
      display: flex;
      align-items: center;
      margin-top: var(--space30);
    }

    button + button {
      margin-left: var(--space30);
    }
  }
`;
