import styled from 'styled-components';

interface MarkerProps {
  selected: boolean;
  status: number;
}

const statusColor = ['var(--action3)', 'var(--problem3)', 'var(--warning2)'];

export const Container = styled.div<MarkerProps>`
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    top: -31px;
    left: -17px;
    color: ${props =>
      props.selected ? statusColor[props.status] : 'var(--support3)'};
  }
`;

export const InfoWindow = styled.div`
  min-height: 80px;
  min-width: 120px;
  width: fit-content;
  background: #fff;
  padding: var(--space10);
  border-radius: 10px;
  box-shadow: var(--shadow-large);
  position: absolute;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  div:first-of-type {
    background: transparent;
    border: 7px solid transparent;
    border-top-color: #fff;
    position: absolute;
    /* top: 50%; */
    /* left: 51px; */
    bottom: 0;
    transform: translate(0, 100%);
  }
`;
