interface LimitTextParams {
  text: string;
  limit: number;
}

export default function limitText({ text, limit }: LimitTextParams): string {
  if (text.length <= limit) {
    return text;
  }

  return `${text.substring(0, limit).trimEnd()}...`;
}
